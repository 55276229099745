import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PharmaNamPic from '../img/pharmanam.png';


function HomePage() {

  return (
    <Row className="mx-auto">
      <Col className='py-5 col-xl-12' style={{maxWidth: 50 + 'rem'}}>
        <h1 className="text-center">Bienvenue sur PharmaNam!</h1>
      <div className="ratio ratio-1x1 rounded-circle overflow-hidden"
           style={{height: '90%', width: '90%', top: '5%', left: '5%'}}>
          <img
            className="h-100 w-100"
            style={{objectFit: 'cover'}}
            src={PharmaNamPic}
            alt="avatar"/>
        </div>
      </Col>
    </Row>
  );
}

export default HomePage;

import { useEffect, useState } from "react";
import { Row, Container, Form, Col, Button, ToggleButton } from "react-bootstrap";
import { SortUp, SortDown, Search, Archive, ArchiveFill } from "react-bootstrap-icons";
import { sort_order } from '../models/Models';

function SearchBar({
  searchText,
  setSearchText,
  submitCallback,
}){

  function handleEnterPressed(event) {
    event.preventDefault();
    event.stopPropagation();

    submitCallback();
  }

  return (
    <Form onSubmit={handleEnterPressed}>
      <Form.Control
        type="search"
        onChange={(x) => setSearchText(x.target.value)}
        placeholder="Search"
        className="me-2"
        aria-label="Search"
      />
    </Form>
  );
}

function DropDownYear({callback}){

  const year = (new Date()).getFullYear();
  const years = Array.from(new Array(year - 1974),( val, index) => year - index );
  
  return (
    <Form.Select onChange={(e) => callback(e.target.value) } className='mx-3'>
      <option value={''}>Tous</option>
      { 

        years.map((year, index) => {
          return <option key={`year${index}`} value={year}>{year}</option>
        })
      }
    </Form.Select>
  )
}

function ProfilesSearchBar({onSearch}) {
  const [searchText, setSearchText] = useState('');
  const [graduationYear, setGraduationyear ] = useState('');

  function submitCallback() {
    onSearch(searchText, graduationYear);
  }

  return (
    <Container className="mt-4 text-center col-xl-8 col-md-10 col-12 justify-content-between">
      <Row>
        <Col className="col-lg-6 col-6">
          <SearchBar searchText={searchText}
                     setSearchText={setSearchText}
                     submitCallback={submitCallback}
          />
        </Col>
        <Col className="col-xl-1 d-xl-block col-0 d-none">
          <span></span>
        </Col>
        <Col className="col-lg-2 col-sm-1 d-lg-flex d-none align-items-center justify-content-end">
          Diplomation:
        </Col>
        <Col className="col-xl-1 col-md-2 col-3">
          <DropDownYear callback={(x) => setGraduationyear(x)}/>
        </Col>
        <Col className="col-lg-2 col-2 pe-md-0">
          <Button type='submit' onClick={() => onSearch(searchText, graduationYear)}>
            Rechercher
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

function EventsSearchBar({onSearch}) {

  const [searchText, setSearchText] = useState('');
  const [sortByDate, setSortByDate ] = useState(null);
  const [includePast,  setIncludePast] = useState(null);


  function submitCallback() {
    onSearch(searchText, sortByDate, includePast);
  }

  useEffect(() => {
    if (sortByDate !== null || includePast !== null)
      submitCallback();

  }, [sortByDate, includePast]);

  function toggleSortByDate(event) {
    setSortByDate((sortByDate == sort_order.desc || !sortByDate)? sort_order.asc : sort_order.desc);
  }

  return (
    <Row className="justify-content-between">
      <Col className="col-xl-6 col-md-6 col-8 ps-1 pe-0 pe-md-1 ps-md-0">
        <SearchBar searchText={searchText} setSearchText={setSearchText} submitCallback={submitCallback}/>
      </Col >
      <Col className="col-xl-2 col-md-2 col-1 px-0">
        <Button className="w-100" type='submit' onClick={submitCallback}>
          <Search size="20" className="me-lg-2"/>
          <span className="d-none d-xl-inline">Rechercher</span>
        </Button>
      </Col>
      <Col className="col-xl-2 col-md-2 col-1 px-sm-1 px-0">
        <Button className="w-100" variant="primary" onClick={toggleSortByDate}>
          { (sortByDate == sort_order.desc || !sortByDate)?
            <SortDown size="20" className="me-lg-2"/> : <SortUp size="20" className="me-lg-2"/>
          }
          <span className="d-none d-xl-inline">Date</span>
        </Button>
      </Col>
      <Col className="col-xl-2 col-md-2 col-1 ps-0 pe-1">
        <ToggleButton
          className="w-100"
          id="toggle-check"
          type="checkbox"
          variant="outline-primary"
          checked={includePast}
          value="1"
          onChange={(e) => setIncludePast(e.currentTarget.checked)}
        >
          { includePast === true  ?
            <ArchiveFill size="20" className="me-lg-2"/> : <Archive size="20" className="me-lg-2"/>
          }
          <span className="d-none d-xl-inline">Archives</span>
        </ToggleButton>
      </Col>
    </Row>
  );
}

function ArticlesSearchBar({onSearch}) {

  const [searchText, setSearchText] = useState('');
  const [sortByDate, setSortByDate ] = useState(null);

  function submitCallback() {
    onSearch(searchText, sortByDate);
  }

  useEffect(() => {
    if (sortByDate !== null)
      submitCallback();

  }, [sortByDate]);

  function toggleSortByDate(event) {
    setSortByDate((sortByDate == sort_order.desc || !sortByDate)? sort_order.asc : sort_order.desc);
  }

  return (
    <Row className="justify-content-between">
      <Col className="col-xl-7 col-md-7 col-9 ps-1 pe-0 pe-md-1 ps-md-0">
        <SearchBar searchText={searchText} setSearchText={setSearchText} submitCallback={submitCallback}/>
      </Col >
      <Col className="col-xl-2 col-md-2 col-1 px-0">
        <Button className="w-100" type='submit' onClick={submitCallback}>
          <Search size="20" className="me-lg-2"/>
          <span className="d-none d-xl-inline">Rechercher</span>
        </Button>
      </Col>
      <Col className="col-xl-2 col-md-2 col-1 ps-0 pe-1">
        <Button className="w-100" variant="primary" onClick={toggleSortByDate}>
          { (sortByDate == sort_order.desc || !sortByDate)?
            <SortDown size="20" className="me-lg-2"/> : <SortUp size="20" className="me-lg-2"/>
          }
          <span className="d-none d-xl-inline">Date</span>
        </Button>
      </Col>
    </Row>
  );
}

export  {
  SearchBar,
  DropDownYear,
  ProfilesSearchBar,
  EventsSearchBar,
  ArticlesSearchBar,
};

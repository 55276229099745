import REST from '../rest/pharma_nam'
import { Alert } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { sleep } from './Users';

function GoogleUserCallbackPage(){
    
    const state = getQueryParameterValue(window.location.href, "state");
    const accessToken = getQueryParameterValue(window.location.href, "access_token");
    const [showAlert, setShowAlert] = useState({ 'success': null, 'msg': '' });

    useEffect( () => {
        async function getFormUrl(){
            const response = await REST.getJSON(
                "google-redirect/user?state="+state+"&access_token="+accessToken
            );
            if(response === null){
                setShowAlert({'success': false, 'msg': 'Erreur Inattendue'});
            } else{
                setShowAlert({'success': true, 'msg': 'Succès, vous allez être redirigé'});
                await sleep(500);
                window.location = response.form_edit_url;
            }
        }
        getFormUrl();
        
    }, []);    

    return (
        <>
        <Alert show={showAlert.success} variant="success" className="mb-3 py-2 text-center">
        {showAlert.msg}
        </Alert>
        <Alert show={showAlert.success === false} variant="danger" className="mb-3 py-2 text-center">
        {showAlert.msg}
        </Alert>
        <div className='d-flex justify-content-center'>Wait a couple of seconds, you will be redirected to Google Forms</div>
        </>
    )
}

function getQueryParameterValue(url, key) {
    url = url.replace('#','&');
    const params = new URLSearchParams(url);
    return params.get(key);
  }

export default GoogleUserCallbackPage;
import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Image, Check2Circle, Circle, Plus, X, ThreeDots, Check2All } from 'react-bootstrap-icons';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import NewAlbumModal from '../components/new_album_dialog.js';

import { PhotoAlbum, RenderPhotoProps } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Download from "yet-another-react-lightbox/plugins/download";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import JSZip from "jszip";
import { saveAs } from 'file-saver';

import { ControlToolbar } from '../components/publications-common';
import { datetime_to_month_string } from '../models/Models';
import { QueueController, QueueItem } from '../components/download-status';

import REST from '../rest/pharma_nam'

const deleteModal = {
  hidden: 0,
  showed: 1,
  confirmed: 2,
}

function AlbumsPage() {
  // Download pictures as (multiple) zip file
  let download_status = new QueueController()
  const max_zip_file_size_MB = 200;
  let current_zipfile = null;
  let current_zipfile_size_MB = 0;
  let current_zipfile_idx = 0;

  const views = {
    photos_view: 0,
    albums_view: 1,
  }

  const btnViewToggleStr = {
    [views.photos_view]: "Vue par albums",
    [views.albums_view]: "Toutes les photos",
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const view_param = params.get('view');

    if (view_param === "albums") {
      setCurrentView(views.albums_view);
    }
    else
      setCurrentView(views.photos_view);
  }, []);

  const [currentView, setCurrentView] = useState(views.photos_view);
  const [pictures, setPictures] = useState([]);
  const [checkedPicturesIdx, setCheckedPicturesIdx] = useState([]);
  const [confirmDeleteModalState, setConfirmDeleteModalState] = useState(deleteModal.hidden);
  const [showNewAlbumModal, setShowNewAlbumModal] = useState(false);

  function toggle_views_with_btn() {
    if (currentView === views.albums_view) {
      setCurrentView(views.photos_view);
      window.history.replaceState({}, '', `?view=photos`);

    }
    else {
      setCurrentView(views.albums_view);
      window.history.replaceState({}, '', `?view=albums`);
    }
  }

  var profile = REST.getProfile();
  let main_content = null;

  if (currentView === views.photos_view) {
    main_content = <DisplayAllImages profile={profile}
                                     sort={"image_date"}
                                     pictures={pictures}
                                     setPictures={setPictures}
                                     checkedCallback={setPictureChecked}/>
  }
  else
    main_content = <DisplayAllAlbums profile={profile}
                                     sort={"event_date"}
                                     showNewAlbumModal={showNewAlbumModal}
                                     setShowNewAlbumModal={setShowNewAlbumModal}/>

  function setPictureChecked(idx, value)
  {
    if (value === true) {
      checkedPicturesIdx.push({idx, value});
    }

    else {
      checkedPicturesIdx.splice(checkedPicturesIdx.findIndex((element) => element.idx === idx), 1);
    }
    setCheckedPicturesIdx([...checkedPicturesIdx]);
    let tmp = pictures.slice();
    tmp[idx].checked = value;
    setPictures(tmp);
  }

  function handleUnselectClicked() {
    const tmp = pictures.map(pic => {
      if (!pic.checked) {
        return pic;
      } else {
        return {
          ...pic,
          checked: false
        };
      }
    });
    // Re-render with the new array
    setPictures(tmp);
    setCheckedPicturesIdx([]);
  }

  function handleSelectAll() {
    const tmp_checked = [];
    let index = 0;
    const tmp_pictures = pictures.map(pic => {
      tmp_checked.push({idx: index++, checked: true});
      return {
        ...pic,
        checked: true
      };
    });
    // Re-render with the new array
    setPictures(tmp_pictures);
    setCheckedPicturesIdx(tmp_checked);
  }

  async function handleDownloadCheckedPictures() {
    download_status.done_callback = imagesDownloadDone;
    let i;
    for (i = 0; i < checkedPicturesIdx.length; i++) {
      let item = new QueueItem(imageDownloadWrapper, pictures[checkedPicturesIdx[i].idx]);
      download_status.insert(item);
    }

    download_status.start();
  }

  async function imageDownloadWrapper(data) {
    if (!data) { return; }
    // create a zipfile if not exists
    if (current_zipfile === null) {
      current_zipfile = new JSZip();
      current_zipfile_size_MB = 0;
    }

    const downloaded_blob = (await fetch(data.src)).blob();
    current_zipfile.file(data.id + "_original." + data.file_format, downloaded_blob);
    current_zipfile_size_MB += downloaded_blob.size / 1000000;

    // early call to ZIP saving function
    if (current_zipfile_size_MB >= max_zip_file_size_MB) {
      await imagesDownloadDone(false);
      current_zipfile = null;
      current_zipfile_idx++;
      current_zipfile_size_MB = 0;
    }
  }

  async function imagesDownloadDone(reset = true) {
    await current_zipfile.generateAsync({type:"blob"})
                         .then(function (blob) {
                           saveAs(blob, "pharma_nam-download-" + current_zipfile_idx + ".zip");
                         });
    if (reset) {
      current_zipfile = null;
      current_zipfile_idx = 0;
      current_zipfile_size_MB = 0;
    }
  }

  async function handleDeleteCheckedPictures(confirmed=false) {
    if (!confirmed) {
      setConfirmDeleteModalState(deleteModal.showed);
      return;
    }

    let i;
    let pictures_copy = [...pictures];
    for (i = 0; i < checkedPicturesIdx.length; i++) {
      REST.delete("images/" + pictures[checkedPicturesIdx[i].idx].id);
      pictures_copy.splice(checkedPicturesIdx[i].idx, 1);
    }
    setCheckedPicturesIdx([]);
    setPictures(pictures_copy);
  }

  useEffect(() => {
    if (confirmDeleteModalState == deleteModal.confirmed)
      handleDeleteCheckedPictures(true);
  }, [confirmDeleteModalState]);

  const deselect_btn = checkedPicturesIdx.length > 0 ? (<Button onClick={() => handleUnselectClicked()}>
                                                          <X size={20}/>
                                                          {"Déselectionner"}
                                                        </Button>) : null;

  const actions_btn = checkedPicturesIdx.length > 0 ? (<Dropdown>
                                                         <Dropdown.Toggle>
                                                           <ThreeDots size={20}/>
                                                         </Dropdown.Toggle>
                                                         <Dropdown.Menu>
                                                           <Dropdown.Item onClick={() => handleDownloadCheckedPictures()}>Télécharger</Dropdown.Item>
                                                           <Dropdown.Item onClick={() => handleDeleteCheckedPictures()}>Supprimer</Dropdown.Item>
                                                         </Dropdown.Menu>
                                                       </Dropdown>) : null;

  return (
    <>
      <ConfirmDeleteModal state={confirmDeleteModalState} setState={setConfirmDeleteModalState} text={"Êtes-vous de sûr de vouloir supprimer les éléments sélecionnés? Cette action est irréversible."}/>
      <Container className="col col-12 py-2 col-xl-9">
        <Stack direction="horizontal" gap={3} className='py-3'>
          <Button onClick={toggle_views_with_btn}>{btnViewToggleStr[currentView]}</Button>
          {( currentView === views.photos_view ) ? (
            <>
              <Button onClick={handleSelectAll}>
                <Check2All size={20}/>
              </Button>
              {deselect_btn}
              {actions_btn}
            </>
          )
           :
           null
          }
          {((profile.role.name === 'admin' || profile.role.name === 'publisher') && currentView === views.albums_view) ?
           <Button onClick={() => setShowNewAlbumModal(true)}>
             <Plus size={20}/>
             {"Nouvel Album"}
           </Button>
           :
           null
          }
        </Stack>
        {main_content}
      </Container>
    </>
  );
}

function DisplayAllImages({
  pictures,
  setPictures,
  profile,
  sort,
  order = 'descending',
  checkedCallback,
}){
  const [jsonResponse, setJsonResponse] = useState(null);
  const [lightboxIndex, setLightboxIndex] = useState(-1);
  const [picturesGroups, setPicturesGroups] = useState([]);
  const [offset, setOffset] = useState(0);
  const itemsPerPage = 100;
  let prev_offset_max = false;

  function gen_static_res_url(tmp_id)
  {
    return REST.baseURL + "static/" + tmp_id;
  }

  useEffect(() => {
    async function startFetching() {
      let response = await REST.getJSON(
        "albums?only_images=1&sort=" + 'image_date'
          + "&order=" + order
          + "&offset=" + offset
          + "&itemsPerPage=" + itemsPerPage
          );
      setJsonResponse(response);
	  }
    startFetching();
  }, [offset]);

  useEffect(() => {
    async function updatePictures() {
      if (jsonResponse) {
        let tmp_pictures = [];
        let tmp_pictures_groups = [];

        let latest_group = Array(2);
        let latest_month = null;

        // in case of offset refetch, we need to get the
        // latest group and month to update it
        if (picturesGroups.length > 0){
          latest_group = picturesGroups.pop()
          latest_month = pictures[pictures.length - 1].date;
        }

        let i;

        if (jsonResponse.count < itemsPerPage) {
          prev_offset_max = true;
        }

        // if no result, push de removed group back
        if (jsonResponse.count < 1) {
          picturesGroups.push(latest_group);
          return;
        }

        for (i = 0; i < jsonResponse.count; i++)
        {
          const recvPicture = jsonResponse.images[i];
          let pic = {};
          pic.index = i + pictures.length;
          pic.id = recvPicture.id;
          pic.checked = false;
          pic.src = recvPicture.original_download.url;
          pic.width = recvPicture.original_download.width_f;
          pic.height = recvPicture.original_download.height_f;
          pic.file_format = recvPicture.original_download.file_format;
          pic.date = new Date(recvPicture.image_date);
          pic.srcSet = []
          pic.srcSet.push({
            src: recvPicture.small_download.url,
            width: recvPicture.small_download.width_f,
            height: recvPicture.small_download.height_f,
          });
          pic.srcSet.push({
            src: recvPicture.medium_download.url,
            width: recvPicture.medium_download.width_f,
            height: recvPicture.medium_download.height_f,
          });

          pic.srcSet.push({
            src: recvPicture.large_download.url,
            width: recvPicture.large_download.width_f,
            height: recvPicture.large_download.height_f,
          });

          tmp_pictures.push(pic);
          let n_idx = i + pictures.length;

          // pictures the server sends are sorted,
          // we check if the mont/year combination is in
          // any previous picture of existing group
          if (latest_month == null) {
            latest_month = pic.date;
            latest_group[0] = n_idx;
            latest_group[1] = n_idx + 1;
          }

          else {
            if (latest_month.getMonth() === pic.date.getMonth()
                && latest_month.getFullYear() === pic.date.getFullYear()) {
              latest_group[1] = n_idx + 1;
            }
            else {
              latest_month = pic.date;
              tmp_pictures_groups.push(latest_group);
              latest_group = Array(2);
              latest_group[0] = n_idx;
              latest_group[1] = n_idx + 1;
            }
          }
        }

        if (i > 0) {
          tmp_pictures_groups.push(latest_group);
          setPicturesGroups([...picturesGroups, ...tmp_pictures_groups]);
          setPictures([...pictures, ...tmp_pictures]);
        }
      }
    }

    updatePictures();
  }, [jsonResponse]);

  async function handleScroll(event) {
    const target = event.target;
    // if there's no more pictures, dont try to fetch them
    if (prev_offset_max)
      return;

    if (target.scrollHeight - target.scrollTop === target.clientHeight)
    {
      setOffset(offset + itemsPerPage);
    }
  }

  // also fetch images when navigating with lightbox
  useEffect(() => {
    async function handleEndOfSlides() {
      if (!prev_offset_max)
        setOffset(offset + itemsPerPage);
    }

    if (lightboxIndex >= 0 && lightboxIndex >= pictures.length - 1) {
      handleEndOfSlides();
    }
  }, [lightboxIndex]);

  const listByMonths = picturesGroups.map(
    group => <DisplayImagesForMonth key={pictures[group[0]].src}
                                    pictures={pictures.slice(group[0], group[1])}
                                    setIndex={setLightboxIndex}
                                    checkedCallback={checkedCallback}/>
  );

  return(
    <>
      <Lightbox
        slides={pictures}
        open={lightboxIndex >= 0}
        index={lightboxIndex}
        on={{ view: ({ index: currentIndex }) => setLightboxIndex(currentIndex) }}
        close={() => setLightboxIndex(-1)}
        carousel={{
          finite: true,
        }}
        plugins={[Slideshow, Thumbnails, Zoom, Download]}
      />
      <div className="overflow-scroll" style={{ minHeight: '10rem', maxHeight: '80vh'}} onScroll={handleScroll}>
        {listByMonths}
      </div>
    </>
  );
}


function DisplayImagesForMonth(
  {
    pictures,
    setIndex,
    checkedCallback,
  }
){
  const first_pic = pictures[0];
  const current_month_str = datetime_to_month_string(first_pic.date);

  return (
    <div>
      <h3 className="mb-0">{current_month_str}</h3>
      <PhotoAlbum layout='rows'
                  spacing='1'
                  padding='3'
                  targetRowHeight='350'
                  width='100'
                  onClick={({ photo }) => setIndex(photo.index)}
                  photos={pictures}
                  renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                    <DisplayImage photo={photo}
                                  key={photo.id}
                                  wrapperStyle={wrapperStyle}
                                  renderDefaultPhoto={renderDefaultPhoto}
                                  checkedCallback={checkedCallback}/>
                  )}
      />
    </div>
  );
};

function DisplayImage({photo, wrapperStyle, renderDefaultPhoto, checkedCallback})
{
  const [checked, setChecked] = useState(photo.checked);
  const [shown, setShown] = useState(false);

  useEffect(() => {
    setChecked(photo.checked);
  }, [photo.checked]);

  function handleCheck(value) {
    setChecked(value);
    checkedCallback(photo.index, value);
  }

  let checkmark = null;
  if (shown || checked) {

    checkmark = checked ?
      <div className="bg-white rounded-circle position-absolute" style={{top: '15px', right: '15px', width: '36px', height: '36px'}}>
        <Check2Circle onClick={() => handleCheck(false)} size={24} className="position-absolute" style={{top: '6px', left: '6px'}}/>
      </div>
    :
    <div className="bg-white rounded-circle position-absolute" style={{top: '15px', right: '15px', width: '36px', height: '36px'}}>
      <Circle onClick={() => handleCheck(true)} size={24} className="position-absolute" style={{top: '6px', left: '6px'}}/>
    </div>
  }

  return (
    <div style={wrapperStyle}
         className={checked ? "position-relative bg-primary" : "position-relative"}
         onMouseEnter={() => setShown(true)}
         onMouseLeave={() => setShown(false)}>
      {checkmark}
      {renderDefaultPhoto({ wrapped: true })}
    </div>
  );
}

function DisplayAllAlbums({
  profile,
  sort,
  order = 'descending',
  showNewAlbumModal,
  setShowNewAlbumModal
}) {

  const renameDialog = {
    hidden: 0,
    showed: 1,
    confirmed: 2,
  };

  const [albums, setAlbums] = useState([]);
  const [confirmDeleteState, setConfirmDeleteState] = useState(deleteModal.hidden);
  const [renameDialogState, setRenameDialogState] = useState(renameDialog.hidden);
  const [shouldReload, setShouldReload] = useState(true);
  const [albumContext, setAlbumContext] = useState({});

  useEffect(() => {
    async function startFetching() {
      if (!shouldReload) {return;}
      let response = await REST.getJSON(
        "albums?sort=" + sort
          + "&order=" + order
          + "&no_images=1"
          + "&offset=0&itemsPerPage=20");
      setAlbums(response.albums);
      setShouldReload(false);
	  }

    startFetching();
  }, [shouldReload]);

  useEffect(() => {
    if (confirmDeleteState == deleteModal.confirmed)
      handleDeleteAlbum(null, true);
  }, [confirmDeleteState]);

  useEffect(() => {
    if (renameDialogState == renameDialog.confirmed)
      handleRenameAlbum(null, true);
  }, [renameDialogState]);

  async function handleDeleteAlbum(album=null, confirmed=false) {
    if (!confirmed) {
      setConfirmDeleteState(deleteModal.showed);
      setAlbumContext(album);
    }
    else {
      setConfirmDeleteState(deleteModal.hidden);
      if (albumContext === null) { return; }
      let resp = await REST.delete('albums/' + albumContext.id + '/');
      setAlbumContext({"id": -1});
      if (resp)
        setShouldReload(true);
    }
  }

  async function handleRenameAlbum(album=null, confirmed=false) {
    if (!confirmed) {
      setAlbumContext(album);
      setRenameDialogState(renameDialog.showed);
    }
    else {
      setRenameDialogState(renameDialog.hidden);
      if (albumContext === null) { return; }
      let resp = await REST.patchJSON('albums/' + albumContext.id + '/', albumContext);
      setAlbumContext({});
      if (resp)
        setShouldReload(true);
    }
  }


  const albums_list = albums.map(
    album => <AlbumItem key={album.name}
                        album={album}
                        profile={profile}
                        handleDeleteAlbum={handleDeleteAlbum}
                        handleRenameAlbum={handleRenameAlbum}
             />
  );

  return (
    <>
      <ConfirmDeleteModal state={confirmDeleteState}
                          setState={setConfirmDeleteState}
                          text={"Êtes-vous sûr de vouloir supprimer l'album? Cette action est irréversible."}
      />
      <NewAlbumModal show={showNewAlbumModal}
                     showSetter={setShowNewAlbumModal}
                     albumList={albums}
                     setAlbumList={setAlbums}/>

      <RenameAlbumModal state={renameDialogState}
                        setState={setRenameDialogState}
                        text={"Renommage d'un album"}
                        album={albumContext}
                        setAlbum={setAlbumContext}
      />
      <Row>
        {albums_list}
      </Row>
    </>
  );
}

function AlbumItem({album, profile, handleDeleteAlbum, handleRenameAlbum}) {
  return (
    <Col xs="auto" className="col-9 col-lg-3 col-md-6 mx-auto mx-lg-0 py-2">
      <Card style={{ height: '15rem', cursor: 'pointer', }}>
        {(profile.role.name === 'admin' || profile.role.name === 'publisher') ?
         <Dropdown>
           <Dropdown.Toggle className="float-end my-0 py-0" variant="none" as={Button}  id="dropdown-basic" style={{ boxShadow: 'none', backgroundColor: 'transparent', border: 'none' }}>
             <ThreeDots/>
           </Dropdown.Toggle>
           <Dropdown.Menu>
             <Dropdown.Item href={album.event_id ? "/event/" + album.event_id : ""} disabled={album.event_id == null}>Évènement</Dropdown.Item>
             <Dropdown.Item disabled={album.event_id != null}
                            onClick={() => handleRenameAlbum(album)}>Renommer</Dropdown.Item>
             <Dropdown.Item disabled={album.event_id != null}
                            onClick={() => handleDeleteAlbum(album)}>Supprimer</Dropdown.Item>
           </Dropdown.Menu>
         </Dropdown>
         :
         null }

        <Card.Body  className="text-center pt-0" onClick={() => { window.location = "/album/" + album.id; }} style={{ flexGrow: '1' }}>
          <Card.Img variant="top" as={ImageIcon} />
          <Card.Title style={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: '3', /* number of lines to show */
            WebkitBoxOrient: 'vertical',
          }}
                      className="text-center text-wrap">
            {album.name}
          </Card.Title>
        </Card.Body>
      </Card>
    </Col>
  );
}

function ImageIcon() {
  return (
    <div className="img-fluid mx-auto">
      <Image size="128"/>
    </div>
  )
}

function ConfirmDeleteModal({
  state,
  setState,
  text,
}) {

  async function handleClose(confirmed) {
    if (confirmed)
      setState(deleteModal.confirmed);
    else
      setState(deleteModal.hidden);
  }

  return (
    <Modal show={state == deleteModal.showed}>
      <Modal.Header closeButton onHide={() => handleClose(false)}>
        <Modal.Title>Confirmation de suppression</Modal.Title>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleClose(false)}>
          Annuler
        </Button>
        <Button variant="danger" onClick={() => handleClose(true)}>
          Supprimer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function RenameAlbumModal ({
  state,
  setState,
  text,
  album,
  setAlbum
}) {

  if (album === null) {
    setAlbum({"id": -1, "name": ""});
  }
  const [error, setError] = useState("");

  async function handleClose(confirmed) {
    if (!confirmed) {
      setState(deleteModal.hidden);
      return;
    }
    if (album.name.length < 3 || album.name.length > 128) {
      setError('Le nom doit contenir entre 3 et 128 caractères');
      return;
    }
    setError('');

    setState(deleteModal.confirmed);
  }

  async function handleSetNewAlbumName(event) {
    setAlbum((getter) => ({ ...album, name: event.target.value }));
  }

  return (
    <Modal show={state === deleteModal.showed}>
      <Modal.Header closeButton onHide={() => handleClose(false)}>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="new_album_name">
            <Form.Label>{text}</Form.Label>
            <Form.Control
              required
              type="text"
              value={album.name}
              onChange={handleSetNewAlbumName}
              isInvalid={!!error}
            />
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(true)}>
            Confirmer
          </Button>
          <Button variant="disabled" onClick={() => handleClose(false)}>
            Annuler
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
export default AlbumsPage;

import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

// the callback for the upload will be called with some
// headers and the file chunk passed as args
// async upload_callback(BLOB: chunk, headers: {}) -> bool
async function chunkedUpload(
  file,
  upload_callback,
  chunk_size_b = 1024 * 1000, // 1MB
  getProgress = false,
  setProgress = false,
  getAbort = false,
) {

  function createChunks(
    file,
    chunk_size_b,
  ) {
    let head = 0;
    let tail = file.size;
    let chunks = [];
    while (head < tail) {
      let chunk_tail = head + chunk_size_b;
      chunks.push(file.slice(head, chunk_tail));
      head = chunk_tail;
    }
    return chunks;
  }

  const size = file.size;
  const chunks = createChunks(file, chunk_size_b);
  const file_upload_id = (Math.random() * 10000000000).toString(16);
  let head = 0;
  let head_p_size = head;
  let chunk_idx = 0;

  for (chunk_idx = 0; chunk_idx < chunks.length && getAbort === false; chunk_idx++)
  {
    head + chunk_size_b >= size ? head_p_size = size : head_p_size = head + chunk_size_b;
    const headers = {
      "X-File-Upload-ID": file_upload_id,
      "Content-Range": 'bytes ' + head + '-' + head_p_size + '/' + size,
    }
    head = head_p_size;

    await upload_callback(chunks[chunk_idx], headers);
    if (setProgress)
      setProgress(chunk_idx / chunks.length);
  }
}

export default chunkedUpload;

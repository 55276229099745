import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './index.scss';

import Layout from './pages/Layout';
import HomePage from './pages/Home';
import ArticlesPage from './pages/Articles';
import ArticlePage from './pages/Article';
import EditArticlePage from './pages/EditArticle';
import EventsPage from './pages/Events';
import EventPage from './pages/Event';
import LoginPage from './pages/Login';
import ProfilePage from './pages/Profile';
import RegisterPage from './pages/Register';
import UsersPage from './pages/Users';
import GoogleUserCallbackPage from './pages/GoogleUserCallbackPage';
import GoogleAdminCallbackPage from './pages/GoogleAdminCallback';
import RefreshTokenPage from './pages/RefreshToken';
import EditEventPage from './pages/EditEvent'
import AncienPage from './pages/Anciens';
import Footer from './pages/Footer';
import EmailValidationPage from './pages/EmailValidation';
import {SponsorsPage} from './pages/Sponsors';
import AlbumsPage from './pages/Albums';
import AlbumPage from './pages/Album';

import REST from './rest/pharma_nam';
export default function App() {

  return (
    <>
    <div style={{minHeight: "75vh", display:"flex", flexDirection: "column"}}>
        <BrowserRouter>
        <Routes>
            <Route element={ <Layout /> }>
            <Route path="/" element={<HomePage />}/>
            <Route path="login" element={<LoginPage />}/>
            <Route path='register' element={<RegisterPage/>}/>
            <Route path="article/:id" element={<ArticlePage />} />
            <Route path="articles" element={<ArticlesPage />} />
            <Route path="new_article" element={<EditArticlePage />} />
            <Route path="edit_article/:id" element={<EditArticlePage />} />
            <Route path="event/:id" element={<EventPage />} />
            <Route path="events" element={<EventsPage />} />
            <Route path="new_event" element={<EditEventPage />} />
            <Route path="edit_event/:id" element={<EditEventPage />} />
            <Route path="profile/:id" element={<ProfilePage />} />
            <Route path="admin/users/" element={<UsersPage/>} />
            <Route path="admin/registrations/" element={<UsersPage/>} />
            <Route path="google-redirect" element={<GoogleAdminCallbackPage/>} />
            <Route path="google-redirect/user" element={<GoogleUserCallbackPage/>} />
            <Route path="admin/refresh-token/" element={<RefreshTokenPage/>} />
            <Route path='anciens' element={<AncienPage/>}/>
            <Route path='email-validation' element={<EmailValidationPage/>}/>
            <Route path='/admin/sponsors' element={<SponsorsPage/>}></Route>
            <Route path='nos_souvenirs' element={<AlbumsPage/>}/>
            <Route path='album/:id' element={<AlbumPage/>}/>
            </Route>
        </Routes>
        </BrowserRouter>
    </div>
    <div>
      <Footer/>
    </div>

    </>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

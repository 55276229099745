import React, { useState } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import REST from '../rest/pharma_nam';

function NewAlbumModal ({
  show,
  showSetter,
  albumList,
  setAlbumList,
}) {

  const [error, setError] = useState('');
  const [album, setAlbum] = useState({id: null, name: ""});

  async function handleClose(confirmed) {
    if (!confirmed) {
      showSetter(false);
      return;
    }
    if (album.name.length < 3 || album.name.length > 128) {
      setError('Le nom doit contenir entre 3 et 128 caractères');
      return;
    }
    setError('');

    let resp = await REST.postJSON('albums/', album)
    if (resp) {
      setAlbumList([...albumList, resp])
      showSetter(false);
      setAlbum({id: null, name: ""});
    }
  }

  async function handleSetNewAlbumName(event) {
    setAlbum((getter) => ({ ...album, name: event.target.value }));
  }

  return (
    <Modal show={show}>
      <Modal.Header closeButton onHide={() => handleClose(false)}>
        <Modal.Title>Creation d'album</Modal.Title>
      </Modal.Header>
      <Form onSubmit={(e) => { e.preventDefault(); handleClose(true); }}>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="new_album_name">
            <Form.Label>Nom de l'album</Form.Label>
            <Form.Control
              required
              type="text"
              value={album.name}
              onChange={handleSetNewAlbumName}
              isInvalid={!!error}
            />
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(true)}>
            Confirmer
          </Button>
          <Button variant="disabled" onClick={() => handleClose(false)}>
            Annuler
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default NewAlbumModal;

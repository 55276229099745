import Cookies from 'universal-cookie';
import { sleep } from '../pages/Users';

const cookies = new Cookies();

class REST {
  static baseURL = "/api/v1/";
  static secure_cookies = null;

  static getTokenHeader() {
    let token = cookies.get("token");
    return 'Bearer ' + token;
  }

  static logged_in = false;

  static get_cookie_secure() {
    if (process.env.REACT_APP_SECURE_COOKIES) {
      if (process.env.REACT_APP_SECURE_COOKIES === "0")
        REST.secure_cookies = false;
      else
        REST.secure_cookies = true;
    }
    else
      REST.secure_cookies = true;

  }

  static async getToken(username, password) {
    const payload = {'email': username, 'password': password};

    if (REST.secure_cookies === null)
      REST.get_cookie_secure();

    const response = await fetch(
      '/api/token/', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    ).then((resp) => { return resp.json(); });

    if ('access' in response) {
      const cookies = new Cookies();
      cookies.set('token', response.access, {
        'sameSite': true,
        'secure': REST.secure_cookies,
      });
      if ('refresh' in response){
        cookies.set('refresh', response.refresh, {
          'sameSite': true,
          'secure': REST.secure_cookies,
        });
      }
      return true;
    }
    return false;
  }

  static async register(email, username, password, first_name, last_name, description){
    
    const obj = {email, username, password, first_name, last_name, description};
    const response = await fetch(
      '/api/v1/profiles/', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      }
    ).then((resp) => {return resp;});
     
    return response;
  }


  static async refreshToken() {
    const payload = {'refresh': cookies.get('refresh')};

    if (REST.secure_cookies == null)
      REST.get_cookie_secure();

    const response = await fetch(
      '/api/token/refresh/', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    ).then((resp) => { return resp.json(); });

    if ('access' in response) {
      const cookies = new Cookies();
      cookies.set('token', response.access, {
        'sameSite': true,
        'secure': REST.secure_cookies,
      });
      if ('refresh' in response)
        cookies.set('refresh', response.refresh, {
          'sameSite': true,
          'secure': REST.secure_cookies,
        });
      REST.logged_in = true;
      return true;
    }
    REST.logged_in = false;
    return false;
  }

  static async getJSON(endpoint) {
    const response = await fetch(
      REST.baseURL + endpoint, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": REST.getTokenHeader(),
        }
      }
    ).then((resp) => {
      if(resp.status === 200 || resp.status === 401){
        return resp.json();
      }
      else {
        return null;
      }
    });

    // error
    if (!response) {
      return response;
    }

    // needs to refresh the token
    if (response.code === "token_not_valid") {
      const refresh_success = await REST.refreshToken();
      if (refresh_success) {
        return await this.getJSON(endpoint);
      }
      else { window.location = "/login"; }
    }
    else {
      return response;
    }
  }

  static async getBLOB(url) {
    const response = await fetch(
      url, {
        headers: {
          "Authorization": REST.getTokenHeader(),
        }
      }
    );
    // token expired
    if(response.status === 401){
      const json = await response.json();
      if (json.code === "token_not_valid") {
        const refresh_success = await REST.refreshToken();
        if (refresh_success) {
          return await this.getBLOB(url);
        }
      }
    }
    else if(response.status === 200){
      return await response.blob();
    }
    // 404 Not found
    else {
      return null;
    }
  };

  static async getBLOBNoAuth(url) {
    const response = await fetch(url);
    // token expired
    if(response.status === 200){
      return await response.blob();
    }
    // 404 Not found
    else {
      return null;
    }
  };

  static async getImage(imageId){
    let imgUrl = REST.baseURL + "images/" + imageId + "/download";
    return await this.getBLOB(imgUrl);
  }

  static async getImageNoAuth(imageId){
    let imgUrl = REST.baseURL + "images/" + imageId + "/download";
    return await this.getBLOBNoAuth(imgUrl);
  }

  static async delete(endpoint) {
    const response = await fetch(
      REST.baseURL + endpoint, {
        method: "DELETE",
        headers: {
          "Authorization": REST.getTokenHeader(),
          "Content-type": "application/json",
        },
      }
    );
    // token expired
    if(response.status === 401){
      const json = await response.json();
      if (json.code === "token_not_valid") {
        const refresh_success = await REST.refreshToken();
        if (refresh_success) {
          return await this.delete(endpoint);
        }
      }
    }
    else if(response.status === 200){
      return true;
    }
    // 404 Not found
    else {
      return null;
    }
  };

  static async patchJSON(endpoint, payload) {
    const response = await fetch(
      REST.baseURL + endpoint, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Authorization": REST.getTokenHeader(),
        },
        body: JSON.stringify(payload),
      }
    );
    // token expired
    if(response.status === 401){
      const json = await response.json();
      if (json.code === "token_not_valid") {
        const refresh_success = await REST.refreshToken();
        if (refresh_success) {
          return await this.patchJSON(endpoint);
        }
      }
    }
    else if(response.status === 200){
      return response.json();
    }
    // 404 Not found, 400 bad request (handle error?)
    else {
      return null;
    }
  };

  // id content_type is null, tautotype deduction is used. FYI if the original file is chunked
  // the content-type cannot be auto deduced.
  static async postFile(endpoint, payload, filename, additional_headers = null, content_type = null) {
    const defaults_headers = {
      "Content-Disposition": "attachment; filename=" + filename,
      "Content-Type": content_type ? content_type : payload.type,
      "Authorization": REST.getTokenHeader(),
    };
    let headers;
    additional_headers ? headers = Object.assign({}, defaults_headers, additional_headers) : headers = defaults_headers;
    const response = await fetch(
      REST.baseURL + endpoint, {
        method: "POST",
        headers: headers,
        body: payload,
      }
    );
    // token expired
    if(response.status === 401){
      const json = await response.json();
      if (json.code === "token_not_valid") {
        const refresh_success = await REST.refreshToken();
        if (refresh_success) {
          return await this.postFile(endpoint, payload, filename, additional_headers, content_type);
        }
      }
    }
    else if(response.status === 200){
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json();
      } else {
        return true;
      }
    }
    else if(response.status === 201){
      return response.json();
    }
    // 404 Not found
    else {
      return null;
    }
  };

  static async putFile(endpoint, payload, filename) {
    const response = await fetch(
      REST.baseURL + endpoint, {
        method: "PUT",
        headers: {
          "Content-Disposition": "attachment; filename=" + filename,
          "Content-Type": payload.type,
          "Authorization": REST.getTokenHeader(),
        },
        body: payload,
      }
    );
    // token expired
    if(response.status === 401){
      const json = await response.json();
      if (json.code === "token_not_valid") {
        const refresh_success = await REST.refreshToken();
        if (refresh_success) {
          return await this.putFile(endpoint, payload, filename);
        }
      }
    }
    else if(response.status === 200){
      return response.json();
    }
    // 404 Not found
    else {
      return null;
    }
  };

  static async postJSON(endpoint, payload) {
    const response = await fetch(
      REST.baseURL + endpoint, {
        method: "POST",
        headers: {
          "Content-Type": 'application/json',
          "Authorization": REST.getTokenHeader(),
        },
        body: JSON.stringify(payload),
      }
    );
    // token expired
    if(response.status === 401){
      const json = await response.json();
      if (json.code === "token_not_valid") {
        const refresh_success = await REST.refreshToken();
        if (refresh_success) {
          return await this.postJSON(endpoint, payload);
        }
      }
    }
    else if(response.status === 200){
      return response.json();
    }
    // 404 Not found
    else {
      return null;
    }
  };

  static async getJSONNoAuth(endpoint) {
    const response = await fetch(
      REST.baseURL + endpoint, {
        headers: {
          "Content-Type": "application/json",
        }
      }
    ).then((resp) => {
      if(resp.status === 200){
        return resp.json();
      }
      else {
        return null;
      }
    });
    return response;
  }

  static discardTokens() {
    cookies.remove('refresh');
    cookies.remove('token');
    this.logged_in = false;
  }


  static getProfile() {
    const profileStorage = sessionStorage.getItem('profile');
    if(profileStorage == null || profileStorage === "undefined"){
        return null;
    }
    return JSON.parse(profileStorage);
  }

  static async fetchProfile(){    

    const profile = REST.getProfile();
    if(profile == null || profile.role.name === "anonymous"){
        return;
    }
    const response = await REST.getJSON('profiles/me');
    if(response === null){
        return;
    }
    sessionStorage.setItem('profile', JSON.stringify(response));
    REST.logged_in = true;
    return response;
  }
}

export default REST;

import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert } from 'react-bootstrap';
import REST from '../rest/pharma_nam'

function EmailValidationPage(){
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const uidb64 = params.get("uidb64");

    const [showAlert, setShowAlert] = useState({ 'success': null, 'msg': '' });
    useEffect( () => {
        async function verifyEmail()  { 
            const response = await REST.getJSONNoAuth(
                `email-validation?token=${token}&uidb64=${uidb64}`
            );
    
            if(response !== null){
                setShowAlert({'success' : true, 'msg': "Adresse email vérifiée avec succès.\nDésormais, il ne reste plus qu'à vous faire accepter par un administrateur"});
            } else
                setShowAlert({'success' : false, 'msg': "Une erreur est survenue"});
            
        }   
        verifyEmail();
        
    }, []); 
    
    return (
        <>
        <Alert show={showAlert.success} variant="success" className="mb-3 py-2 text-center">
        {showAlert.msg}
        </Alert>
        <Alert show={showAlert.success === false} variant="danger" className="mb-3 py-2 text-center">
        {showAlert.msg}
        </Alert> 
        <Container>
            <Row >
                <Col className='d-flex justify-content-center mt-4'>Attendez un instant, le temps qu'on vérifie l'email</Col>
            </Row>
        </Container>
        </>
    )
}

export default EmailValidationPage;
import REST from '../rest/pharma_nam'
import { Alert } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { sleep } from './Users';
import { useNavigate } from "react-router-dom";

function GoogleAdminCallbackPage(){
    
    const state = getQueryParameterValue(window.location.href, "state");
    const code = getQueryParameterValue(window.location.href, "code");
    const [showAlert, setShowAlert] = useState({ 'success': null, 'msg': '' });
    const navigate = useNavigate();
    useEffect( () => {
        async function postData () {
            const response = await REST.postJSON(
                "google-redirect/?state="+state+"&code="+code+'/'
            );

            if(response !== null && response.success === true){
                setShowAlert({'success': true, 'msg': 'Succès, vous allez être redirigé'});
                await sleep(1000);
                navigate('/');
            } else {
                setShowAlert({'success': false, 'msg': 'Erreur inattendue'});
            }
        }
        postData();
        
    }, []);  
    
    return (
    <>
        <Alert show={showAlert.success} variant="success" className="mb-3 py-2 text-center">
        {showAlert.msg}
        </Alert>
        <Alert show={showAlert.success === false} variant="danger" className="mb-3 py-2 text-center">
        {showAlert.msg}
        </Alert>
        <div className='d-flex justify-content-center'>Patientez quelques instants, le token va être regénéré. Vous serez redirigé vers la page d'accueil</div>
    </>
    )
}

function getQueryParameterValue(url, key) {
    url = url.replace('?','&');
    const params = new URLSearchParams(url);
    return params.get(key);
  }

export default GoogleAdminCallbackPage;
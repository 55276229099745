import { Button, Modal } from "react-bootstrap";

function BasicConfirmationDialog({onConfirm, onClose, show, title, text}) {
    return (
      <Modal
        show={show}
        onHide={() => onClose()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {text}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(x) => onConfirm(x)}>Confirmer</Button>
          <Button onClick={(x) => onClose(x)}>Annuler</Button>
        </Modal.Footer>
      </Modal>
    );
  }

function BasicInputDialog({form, show, onConfirm, onClose, title}){
    return (
      <Modal
        show={show}
        onHide={() => onClose()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {form}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(x) => onConfirm(x)}>Confirmer</Button>
          <Button onClick={(x) => onClose(x)}>Annuler</Button>
        </Modal.Footer>
      </Modal>
    );

}
  export {BasicConfirmationDialog, BasicInputDialog};
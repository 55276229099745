import React, { useState, useEffect} from 'react';
import REST from '../rest/pharma_nam';
import { Card, ListGroup, Pagination, Button, Row, Container, Form, Col, Placeholder } from 'react-bootstrap';
import { ProfilesSearchBar } from '../components/searchbar';
import { DisplayProfilePicture } from './Profile';
import BlankAvatar from '../img/pharmanam.png';
import { sleep } from './Users';
import FailurePage from './FailurePage';
const itemsPerPage = 16;
let query = null;

function AncienPage(){
    const [users, setUsers] = useState([]);
    const [active, setActive] = useState(1);
    const [isLoaded, setIsLoaded] = useState(false);
    const [pages, setPages] = useState([]);    
    const [nbPages, setNbPages] = useState(3);
    
    
    let profile = REST.getProfile();


    useEffect(() => {
      let tmpPages = [];
        if(!profile || profile.role.name === "anonymous"){
            return;
      }
      for (let number = 1; number <= nbPages; number++) {
        tmpPages.push(
          <Pagination.Item onClick={() => {setActive(number); setIsLoaded(false);}} key={number} active={number === active}>
            {number}
          </Pagination.Item>,
        );
      }
      setPages(tmpPages);
      if(isLoaded === false)
      {
        loadPage(query, null); // active - 1 because we start counting pages at 1 and not 0 
        setIsLoaded(true);
      }
    }, [nbPages, active]);

    if(!profile || profile.role.name === "anonymous"){
        return <FailurePage/>;
    }
    
    return (
        <>
        <div className='d-flex justify-content-center'>
          <ProfilesSearchBar onSearch={(searchTxt, graduationYear) => loadPage(searchTxt, graduationYear)} />
        </div>
        <div className=''>
          <AnciensList profiles={users}></AnciensList>
        </div>
        <div className='d-flex justify-content-center py-5'>
          <Pagination>{pages}</Pagination>
        </div>
        
        </>
    );

    function loadPage(query, graduationYear){
      let fetchDone = false;
      
      const response =  REST.getJSON( 
        `ancients?offset=${active - 1}&itemsPerPage=${itemsPerPage}&query=${query ? query : ''}&gradYear=${graduationYear ? graduationYear : ''}`
      );
      response.then(async (response) => {
          
          if(response === null){
              return;
          }
    
          const nbPages = Math.ceil(response.total / itemsPerPage);
          setNbPages(nbPages);
           
          const profiles = await Promise.all(
            response.profiles.map(async (profile) =>  <AncienCard key={profile.id} profile={profile} picture={ await getImageUrlForProfile(profile)}/> )
          );
          fetchDone = true;
          
          setUsers(profiles);
        });
    
        const timer = sleep(100);
        timer.then( (x) => {
          if (!fetchDone) {
            let placeholder = [];
            for (let i = 0; i < itemsPerPage; i++) {
              placeholder.push(getPlaceholder(i));
            }
            setUsers(placeholder);
          }
        });
    
    
    }

}





async function getImageUrlForProfile(profile) {

  let picture = profile.profilePicture === null ? BlankAvatar : profile.profilePicture.url;
  return picture;
}

function AnciensList({profiles}){
    return (
    <>
        <ListGroup className='d-flex flex-wrap justify-content-center' horizontal> 
        {profiles}
        </ListGroup>    
    </>
    )
}

function AncienCard({profile, picture}){
    return (
        <div key={profile.id} className="mx-2 px-4 py-4 ">
        <Card style={{height:'400px', width:'350px', borderRadius: '35px'}}>
            <div className='mt-3' style={{maxHeight: '275px'}} >
                <DisplayProfilePicture picUrl={picture}/>
                <div className='d-flex justify-content-center pt-1'><strong>{profile.graduationYear}</strong>
            </div>
            </div>
            <Card.Body className="text-center">
                <Card.Title>{profile.last_name} {profile.first_name}</Card.Title>
                <Card.Text>
                { profile.description.length < 105 ? profile.description : profile.description.slice(0,105) + '...'}
                </Card.Text>
                <Button variant='primary' style={{position:'absolute', top:'378px', right:'125px'}} href={"profile/"+profile.id}> Voir profil
                </Button>
            </Card.Body>
        </Card>
        </div>
      );
}

function getPlaceholder(i) { 
    return (
      <div key={i} className="px-4 py-4">
        <Card style={{height:'400px', width:'350px'}}>
            <Card.Body>
            <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={4}/>
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
                <Placeholder xs={6}/>
            </Placeholder>
            <Placeholder.Button variant="primary" animation="glow" xs={2}/>
            </Card.Body>
        </Card>
        </div>
    );
  }



export default AncienPage;

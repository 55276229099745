import React, { useState } from 'react';
import Cookies from 'universal-cookie';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Alert } from 'react-bootstrap';

import REST from '../rest/pharma_nam'

function LoginPage() {
  const [passwordType, setPasswordType] = useState("password");
  const [password, setPassword] = useState("");
  const profileStorage = sessionStorage.getItem("profile");
  const [username, setUsername] = useState(profileStorage ? JSON.parse(profileStorage).username : '');
  const [showAlert, setShowAlert] = useState({ 'success': null, 'msg': '' });

  function showPasswordChange(event) {
    if (event.target.checked) {
      setPasswordType('text');
    }
    else {
      setPasswordType('password');
    }
  }

  return (
    <Container className='py-5' style={{maxWidth: 25 + 'rem'}}>
      <Alert show={showAlert.success} variant="success" className="mb-3 py-2 text-center">
        {showAlert.msg}
      </Alert>
      <Alert show={showAlert.success === false} variant="danger" className="mb-3 py-2 text-center">
        {showAlert.msg}
      </Alert>
      <Form >
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Adresse Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={username}
            onChange={(x) => setUsername(x.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Mot de passe</Form.Label>
          <Form.Control
            type={passwordType}
            value={password}
            onChange={(x) => setPassword(x.target.value)}
            placeholder="Password" />
        </Form.Group>
        <Form.Group className="mb-3"  controlId="formBasicCheckbox">
          <Form.Check
            reverse
            type="switch"
            label="Afficher le mot de passe"
            onChange={(x) => showPasswordChange(x)}
          />
        </Form.Group>
        <div className="d-grid">
          <Button
            onClick={() => login(username, password, (y) => setShowAlert(y))}
            variant="primary">
            Soumettre
          </Button>
        </div>
      </Form>
    </Container>
  );
}

function login(username, password, alertSetter) {
  alertSetter({ 'success': null, 'msg': '' });

  async function getToken() {
    const success = await REST.getToken(username, password);
    if (success) {
      alertSetter({'success': true, 'msg': "Succès"});
      await getProfile();
      window.location = '/';
    }
    else {
      alertSetter({'success': false, 'msg': "Veuillez réessayer"});
    }
  }

  getToken();
}

async function getProfile() {
  const response = await REST.getJSON('profiles/me');
  sessionStorage.setItem('profile', JSON.stringify(response));
  REST.logged_in = true;
}

export default LoginPage;

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Outlet } from "react-router-dom";

import REST from '../rest/pharma_nam';
import img from '../img/person-circle-outline.svg';
import { useEffect, useState } from 'react';
import { sleep } from './Users';

function Layout() {

  let profile = REST.getProfile();
  if (!profile) {
    profile = setAnonymousProfile();
  }

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/">Pharma Nam</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Accueil</Nav.Link>
              {profile.role.name !== 'anonymous' && (
                <>
                  <Nav.Link href='/anciens'>Anciens</Nav.Link>
                  <Nav.Link href='/nos_souvenirs'>Nos souvenirs</Nav.Link>
                </>
              )}
              <NavDropdown title="Publications" id="basic-nav-dropdown">
                <NavDropdown.Item href="/articles">Articles</NavDropdown.Item>
                <NavDropdown.Item href="/events">Évenements</NavDropdown.Item>
              </NavDropdown>
              {profile.role.name === 'admin' &&
               <NavDropdown title="Admin" id="basic-nav-dropdown">
                 <NavDropdown.Item href="/admin/users">Utilisateurs</NavDropdown.Item>
                 <NavDropdown.Item href="/admin/registrations">Inscriptions</NavDropdown.Item>
                 <NavDropdown.Item href="/admin/sponsors">Sponsors</NavDropdown.Item>
                 <NavDropdown.Item href='/admin/refresh-token/'>Token</NavDropdown.Item>
               </NavDropdown>
              }
            </Nav>
            {profile.role.name !== 'anonymous' ?
             <Nav>
               <Navbar.Brand href="/profile/me">
                 <img
                   alt=""
                   src={img}
                   width="30"
                   height="30"
                   className="d-inline-block align-top"
                 />{' ' + profile.first_name}
               </Navbar.Brand>
               <Nav.Link onClick={() => logout()}>Déconnexion</Nav.Link>
             </Nav>
             :
             <Nav>
               <Nav.Link onClick={() => window.location = '/login'}>Connexion</Nav.Link>
             </Nav> 
            }
            {profile.role.name === 'anonymous' && 
             <Nav>
               <Nav.Link onClick={() => window.location = '/register'}>Inscription</Nav.Link>
             </Nav> }
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet/>
    </>
  );
}




function logout() {
  setAnonymousProfile();
  REST.discardTokens();
  window.location = "/";
}

function setAnonymousProfile() {
  const profile = {
    'role': {'name' : 'anonymous'}
  };
  sessionStorage.setItem('profile', JSON.stringify(profile));
  return profile;
}

export default Layout;

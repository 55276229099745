import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import {Image} from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';
import Collapse from 'react-bootstrap/Collapse';
import Fade from 'react-bootstrap/Fade';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Placeholder } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion'

import { Article } from '../models/Models';
import REST from '../rest/pharma_nam';

import BlankAvatar from '../img/pharmanam.png';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';

import imageCompression from 'browser-image-compression';
import { BasicConfirmationDialog } from '../components/dialogs';

function ProfilePage() {
  const [profile, setProfile] = useState(null);
  const [modify, setModify] = useState(false);
  const [picture, setPicture] = useState(BlankAvatar);
  const [fetchDone, setFetchDone] = useState(false);
  let profile_id = useParams().id;
 
  REST.fetchProfile();

  useEffect(() => {
    async function getpro() {
      var profileResponse;
      if (profile_id === 'me') {
        profileResponse = REST.getProfile();
      }
      else {
        profileResponse = await REST.getJSON('profiles/' + profile_id);
      }
      setProfile(profileResponse);

      if (profileResponse.profilePicture){
        setPicture(profileResponse.profilePicture.url);
      }

      setFetchDone(true);
    }
      getpro();
  }, []);

  if(!fetchDone){
    return (<></>);
  }

  // TODO use placeholder
  return (modify ?
          <ModifyProfile
            modifySetter={setModify}
            profileGetter={profile}
            profileSetter={setProfile}
            pictureGetter={picture}
            pictureSetter={setPicture}
          />
          :
          <DisplayProfile
            profile={profile}
            profile_id={profile_id}
            picture={picture}
            modifySetter={setModify}/>
         );
}


function getProfile() {
  let profileStorage = sessionStorage.getItem('profile');
  return profileStorage ? JSON.parse(profileStorage) : null;
}

function pictureChanged(event, setter) {
  if (event.target.files && event.target.files[0]) {
    setter(URL.createObjectURL(event.target.files[0]));
  }
}

function DisplayProfile({
  profile,
  profile_id,
  picture,
  modifySetter,
}) {
    
  return (
    <Collapse in={true} appear={true}>
      <Container className='py-3' style={{maxWidth: 60 + 'rem'}}>
        {profile  ?
         <Row className="g-3">
           <Col className="col-10 mx-auto col-sm-5">
             <Card>
               <div className='my-4'>
                 <DisplayProfilePicture picUrl={picture}/>
               </div>
               <Card.Body className="text-center">
                 <Card.Title >{profile.first_name} {profile.last_name}</Card.Title>
                 <Card.Text>
                   {profile.description}
                 </Card.Text>
                 <Button variant="primary" href={profile.linkedInProfile}>
                   <img alt="Linkedin" src="https://content.linkedin.com/content/dam/me/business/en-us/amp/brand-site/v2/bg/LI-Bug.svg.original.svg"/>
                 </Button>
               </Card.Body>
             </Card>
           </Col>
           <Col className="col-10 mx-auto col-sm-7">
             <Card className="py-0 px-0 h-100">
               <Card.Header className="d-flex justify-content-between">
                 <div className="h4 text-start text">
                   Coordonnées
                 </div>
                 {profile_id === 'me' &&
                  <div className="d-flex gap-2">
                    <Button variant="outline-primary" onClick={() => modifySetter(true)}>
                      Modifier
                    </Button>
                  </div>
                 }
               </Card.Header>

               <Card.Body>
                 <div className="py-2 input-group">
                   <span className="input-group-text">Nom</span>
                   <input readOnly
                          name="first_name"
                          value={profile.first_name}
                          type="text"
                          className="form-control"/>
                 </div>
                 <div className="py-2 input-group">
                   <span className="input-group-text">Prenom</span>
                   <input readOnly
                          value={profile.last_name}
                          name="last_name"
                          type="text"
                          className="form-control"/>
                 </div>
                 <div className="py-2 input-group">
                   <span className="input-group-text">Email</span>
                   <input readOnly
                          value={profile.email}
                          name="username"
                          type="text"
                          className="form-control"/>
                 </div>
                 <div className="py-2 input-group">
                   <span className="input-group-text">Téléphone</span>
                   <input readOnly
                          value={profile.tel}
                          name="tel"
                          type="text"
                          className="form-control"/>
                 </div>
                 <div className="py-2 input-group">
                   <span className="input-group-text">Année de diplomation</span>
                   <input readOnly
                          value={profile.graduationYear}
                          name="graduationYear"
                          type="text"
                          className="form-control"/>
                 </div>
               </Card.Body>
             </Card>
           </Col>
         </Row>
         :
         <Alert variant="danger">
           Profil introuvable...
         </Alert>
        }
      </Container>
    </Collapse>
  );
}

function DisplayProfilePicture({
  picUrl,
}) {
  return (
    <div style={{maxWidth: '60%'}}
         className="ratio ratio-1x1 mx-auto border rounded-circle shadow border-grey border-2">
      <div className="ratio ratio-1x1 rounded-circle overflow-hidden"
           style={{height: '90%', width: '90%', top: '5%', left: '5%'}}>
        <img
          className="h-100 w-100"
          style={{objectFit: 'cover'}}
          src={picUrl ? picUrl : BlankAvatar}
          alt="avatar"/>
      </div>
    </div>
  );
}

function ModifyProfile({
  modifySetter,
  profileGetter,
  profileSetter,
  pictureGetter,
  pictureSetter,
}) {
  const [pictureModified, setPictureModified] = useState(false);
  const [detailsModified, setDetailsModified] = useState(false);
  const [tmpPicture, setTmpPicture] = useState(pictureGetter);
  const [tmpProfile, setTmpProfile] = useState(profileGetter);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [detailsValidated, setDetailsValidated] = useState(false);
  const fileRef = React.useRef();
  const [email, setEmail] = useState(getProfile().email);
  const [password, setPassword] = useState("***********");
  const [showDialog, setShowDialog] = useState({"state":false, "object": {"title": "title", "text": "text", "onConfirm" : () => console.log("")}});
  function handleDetailsChange(event) {
    const { name, value } = event.target;
    setTmpProfile((getter) => ({ ...tmpProfile, [name]: value }));
    if (!detailsModified)
      setDetailsModified(true);
  }

  function handleDetailsSubmit(event) {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === true) {
      updateProfile();
    }

    setDetailsValidated(true);
    sessionStorage.setItem('profile', JSON.stringify(profileGetter));
  }

  async function updateProfile() {
    const response = await REST.patchJSON('profiles/' + profileGetter.id + '/', tmpProfile);
    if (response) {
      profileSetter(response);
      modifySetter(false);
    }
  }

  async function compressImg(file) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 500
    }
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }

  function handlePictureSubmit(event) {
    // call API
    if (profileGetter.profilePicture)
      updatePicture();
    else
      createPicture();
  }

  async function updatePicture() {
    const response = await REST.putFile('profiles/' + profileGetter.id + '/profile_picture/',
                                        fileToUpload,
                                        fileToUpload.name,
                                       );
    if (response) {
      pictureSetter(tmpPicture);
      modifySetter(false);
      profileSetter((getter) => ({ ...getter, ['profilePicture']: response}));
      sessionStorage.setItem('profile', JSON.stringify(profileGetter));
    }
  }

  async function createPicture() {
    const response = await REST.postFile('profiles/' + profileGetter.id + '/profile_picture/',
                                         fileToUpload,
                                         fileToUpload.name,
                                        );
    if (response) {
      pictureSetter(tmpPicture);
      modifySetter(false);
      profileSetter((getter) => ({ ...getter, ['profilePicture']: response}));
      sessionStorage.setItem('profile', JSON.stringify(profileGetter));
    }
  }

  function handlePictureChanged(event) {
    if (!event) {
      setPictureModified(false);
      fileRef.current.value = "";
      setTmpPicture(pictureGetter);
      setFileToUpload(null);
    }
    else if (event.target.files && event.target.files[0]) {
      compressImg(event.target.files[0]).then((x) => {
        setFileToUpload(new File([x], event.target.files[0].name));
      });
      setTmpPicture(URL.createObjectURL(event.target.files[0]));
      setPictureModified(true);
    }
  }

  return (
    <Collapse in={true} appear={true}>
      <Container className='py-2 col-xxl-5 col-xl-6 col-md-10 col-sm-10 col-11'>
        <Stack direction='horizontal' className='py-2 justify-content-between'>
          <div className='h4 text-center'>Modifier votre profile</div>
          <Button variant="outline-secondary" onClick={() => modifySetter(false)}>Retour</Button>
        </Stack>
        <Accordion defaultActiveKey='0'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header className='h4 text-center'>Photo</Accordion.Header>
            <Accordion.Body>
              <Card className='mb-2'>
                <Card.Body className='w-50 mx-auto'>
                  <DisplayProfilePicture picUrl={tmpPicture}/>
                </Card.Body>
                <Card.Footer>
                  <Stack direction='horizontal' className='justify-content-end' gap='2'>
                    <div className="input-group">
                      <input type="file" ref={fileRef}
                             onChange={(x) => handlePictureChanged(x)}
                             className="form-control"/>
                      <Button onClick={() => handlePictureChanged(null)} variant="outline-secondary">Annuler</Button>
                    </div>
                    <Button onClick={() => handlePictureSubmit()} disabled={!pictureModified} variant="primary">Enregistrer</Button>
                  </Stack>
                </Card.Footer>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='1'>
            <Accordion.Header className='h5 text-center'>Coordonnées</Accordion.Header>
            <Accordion.Body>
              <Card>
                <Form noValidate validated={detailsValidated} onSubmit={handleDetailsSubmit}>
                  <Card.Body>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Prénom</Form.Label>
                      <Form.Control
                        required
                        pattern="^[a-zA-Z][a-zA-Z\- ]*$"
                        type="text"
                        name="first_name"
                        value={tmpProfile.first_name}
                        onChange={(x) => handleDetailsChange(x)}/>
                      <Form.Control.Feedback type="invalid">
                        Prénom non conforme
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Nom</Form.Label>
                      <Form.Control
                        required
                        pattern="^[a-zA-Z][a-zA-Z\- ]*$"
                        type="text"
                        name="last_name"
                        value={tmpProfile.last_name}
                        onChange={(x) => handleDetailsChange(x)}/>
                      <Form.Control.Feedback type="invalid">
                        Nom non conforme
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Téléphone</Form.Label>
                      <Form.Control
                        type="text"
                        name="tel"
                        pattern="^[0-9-\+][0-9]*$"
                        value={tmpProfile.tel}
                        onChange={(x) => handleDetailsChange(x)}/>
                      <Form.Control.Feedback type="invalid">
                        Numéro de téléphone uniquement composé de chiffres
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Année de diplomation</Form.Label>
                      <Form.Control
                        type="text"
                        name="graduationYear"
                        pattern="^[1-2][0-9]..$"
                        value={tmpProfile.graduationYear}
                        onChange={(x) => handleDetailsChange(x)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        name="description"
                        value={tmpProfile.description}
                        onChange={(x) => handleDetailsChange(x)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>LinkedIn</Form.Label>
                      <Form.Control
                        type="text"
                        name="linkedInProfile"
                        value={tmpProfile.linkedInProfile}
                        onChange={(x) => handleDetailsChange(x)}/>
                    </Form.Group>
                  </Card.Body>
                  <Card.Footer>
                    <Stack direction='horizontal' className='justify-content-end' gap='2'>
                      <Button type="submit" disabled={!detailsModified} variant="primary">Enregistrer</Button>
                      <Button onClick={() => {setDetailsModified(false); setTmpProfile(profileGetter);}}
                              variant="outline-secondary">Annuler</Button>
                    </Stack>
                  </Card.Footer>
                </Form>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Header className='h4 text-center'>Utilisateur</Accordion.Header>
            <Accordion.Body>
              <Form className=''>
              <Row className="d-flex justify-space-evenly mb-3">
                <Col>
                  <Form.Group controlId="formBasicEmail">
                      <Form.Label>Adresse Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={REST.getProfile().email}
                        onChange={(x) => setEmail(x.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col >
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Mot de passe</Form.Label>
                      <Form.Control
                        type="password"
                        value={password}
                        onChange={(x) => setPassword(x.target.value)}
                        placeholder="Password" />
                    </Form.Group>
                    </Col>
                    </Row>
                 </Form>
              <Row className="text-center g-2 row-cols-1 row-cols-md-3">
                <Col>
                  <Button variant="primary" onClick={() => setShowDialog({"state":true, "object": {"title": "Modification d'email", "text": "Êtes vous sûr de vouloir modifier votre adresse email?", "onConfirm": () => modifyCredentials(email, null, profileGetter.id, (x) => profileSetter(x), (x) => modifySetter(x))}})}>Modifier l'adresse mail</Button>
                </Col>
                <Col>
                  <Button variant="warning" onClick={() => setShowDialog({"state":true, "object": {"title": "Modification de mot de passe", "text": "Êtes vous sûr de vouloir modifier votre mot de passe?", "onConfirm": () => modifyCredentials(null ,password, profileGetter.id, (x) => profileSetter(x), (x) => modifySetter(x))}})}>Changer de mot de passe</Button>
                </Col>
                <Col>
                  <Button variant="danger" onClick={() => setShowDialog({"state":true, "object": {"title": "Suppression de compte", "text": "Êtes vous sûr de vouloir supprimer votre compte?", "onConfirm": () => deleteAccount(profileGetter.id, (x) => modifySetter(x))}})}>Supprimer ce compte</Button>
                </Col>
              </Row>
              <BasicConfirmationDialog 
                text={showDialog.object.text}
                title={showDialog.object.title}
                show={showDialog.state} 
                onConfirm={() => {showDialog.object.onConfirm(); let tmp = {...showDialog}; tmp.state = false; setShowDialog(tmp); }}
                onClose={() => {let tmp = {...showDialog}; tmp.state = false; setShowDialog(tmp);}}
                >

              </BasicConfirmationDialog>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </Collapse>
  );
}


function modifyCredentials(mail, pwd, profileId, profileSetter, modifySetter){
  async function updateCredentials(mail, pwd){
    const response = await REST.patchJSON('user/' + profileId + '/', mail != null ? {"email":mail} : {"password" : pwd});
    if (response !== null) {
      profileSetter(response)
      modifySetter(false);
    }
  }
  updateCredentials(mail, pwd); 
}

function deleteAccount(profileId, modifySetter){
  async function delAcc(){
    const response = await REST.delete('user/' + profileId);
    if (response !== null){
      modifySetter(false);
      REST.discardTokens();
      sessionStorage.setItem('profile', null);
    }
  }
  delAcc();
}


export default ProfilePage;
export {DisplayProfilePicture};

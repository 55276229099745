import { Stack } from "react-bootstrap";


/// Use this component whenever a user lands on a page/url that he's not supposed to have access to
function FailurePage(){
    
    return (
        <Stack className="py-4">
            <div className="d-flex justify-content-center">
                Il semblerait que vous vous êtes perdu sur le site internet.
            </div>
            <a className="d-flex justify-content-center" href="/">Revenir sur la page d'accueil</a>

        </Stack>
    )

}


export default FailurePage;
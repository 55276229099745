import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

function ControlToolbar({
  new_publication_str,
  link_str,
}) {
  return (
    <Stack direction="horizontal" gap={3} className='py-3'>
      <Button href={link_str}>{new_publication_str}</Button>
    </Stack>
  );
}

export {ControlToolbar};

import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Images } from "react-bootstrap-icons";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { Image, Check2Circle, Circle, Plus, X, ThreeDots, Check2All } from 'react-bootstrap-icons';

import chunkedUpload from '../components/chunk-uploader';
import NewAlbumModal from '../components/new_album_dialog.js';

import { PhotoAlbum, RenderPhotoProps } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Download from "yet-another-react-lightbox/plugins/download";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import JSZip from "jszip";
import { saveAs } from 'file-saver';
import { QueueController, QueueItem } from '../components/download-status';
import REST from '../rest/pharma_nam';

const deleteModal = {
  hidden: 0,
  showed: 1,
  confirmed: 2,
}

function AlbumPage() {

  const imageHiddenInputRef = useRef(null);

  let download_status = new QueueController()
  const max_zip_file_size_MB = 200;
  let current_zipfile = null;
  let current_zipfile_size_MB = 0;
  let current_zipfile_idx = 0;

  var profile = REST.getProfile();
  let album_id = useParams().id;
  const [fetchDone, setFetchDone] = useState(null);
  const [album, setAlbum] = useState(null);
  const [pictures, setPictures] = useState([]);
  const [checkedPicturesIdx, setCheckedPicturesIdx] = useState([]);
  const [lightboxIndex, setLightboxIndex] = useState(-1);
  const [confirmDeleteModalState, setConfirmDeleteModalState] = useState(deleteModal.hidden);

  useEffect(() => {
    async function getAlbum() {
      const response = await REST.getJSON("albums/" + album_id);
      setFetchDone(true);
      setAlbum(response);
    }

    getAlbum();
  }, []);

  async function checkedCallback(idx, value)
  {
    if (value === true) {
      checkedPicturesIdx.push({idx, value});
    }

    else {
      checkedPicturesIdx.splice(checkedPicturesIdx.findIndex((element) => element.idx === idx), 1);
    }
    setCheckedPicturesIdx([...checkedPicturesIdx]);
    let tmp = pictures.slice();
    tmp[idx].checked = value;
    setPictures(tmp);
  }

  useEffect(() => {
    async function updatePictures() {
      if (album && album.pictures) {
        let picturesTMP = [];

        for (let i = 0; i < album.pictures.length; i++)
        {
          const recvPicture = album.pictures[i];
          let pic = {};
          pic.src = recvPicture.original_download.url;
          pic.width = recvPicture.original_download.width_f;
          pic.height = recvPicture.original_download.height_f;
          pic.checked = false;
          pic.index = i;
          pic.file_format = recvPicture.original_download.file_format;
          pic.id = recvPicture.id;
          pic.srcSet = []
          pic.srcSet.push({
            src: recvPicture.small_download.url,
            width: recvPicture.small_download.width_f,
            height: recvPicture.small_download.height_f,
          });
          pic.srcSet.push({
            src: recvPicture.medium_download.url,
            width: recvPicture.medium_download.width_f,
            height: recvPicture.medium_download.height_f,
          });

          pic.srcSet.push({
            src: recvPicture.large_download.url,
            width: recvPicture.large_download.width_f,
            height: recvPicture.large_download.height_f,
          });

          picturesTMP.push(pic);
        }

        setPictures(picturesTMP);
      }
    }

    updatePictures();
  }, [album]);

  async function showFilePicker() {
    imageHiddenInputRef.current.click();
  }

  async function postFileWrapper(chunk, headers) {
    const f = imageHiddenInputRef.current.files[0];
    const out = await REST.postFile("albums/" + album_id + "/image/", chunk, f.name, headers, f.type);
    if (typeof out === "boolean"){
      // chunk
    }
    else {
      // upload completed
      setAlbum((getter) => ({ ...getter, ['pictures']: [ ...(getter.pictures ?? []), out ] }));
    }
  }

  async function fileUploadInputChanged(event) {
    if (event.target.files) {
      for (let i = 0; i < event.target.files.length; i++)
      {
        const out = await chunkedUpload(event.target.files[i], postFileWrapper);
      }
    }
  }

  async function handleSelectAll()
  {
    const tmp_checked = [];
    let index = 0;
    const tmp_pictures = pictures.map(pic => {
      tmp_checked.push({idx: index++, checked: true});
      return {
        ...pic,
        checked: true
      };
    });
    // Re-render with the new array
    setPictures(tmp_pictures);
    setCheckedPicturesIdx(tmp_checked);
  }

  function handleUnselectClicked() {
    const tmp = pictures.map(pic => {
      if (!pic.checked) {
        return pic;
      } else {
        return {
          ...pic,
          checked: false
        };
      }
    });
    // Re-render with the new array
    setPictures(tmp);
    setCheckedPicturesIdx([]);
  }

  async function handleDownloadCheckedPictures() {
    download_status.done_callback = imagesDownloadDone;
    let i;
    for (i = 0; i < checkedPicturesIdx.length; i++) {
      let item = new QueueItem(imageDownloadWrapper, pictures[checkedPicturesIdx[i].idx]);
      download_status.insert(item);
    }

    download_status.start();
  }

  async function imageDownloadWrapper(data) {
    if (!data) { return; }
    // create a zipfile if not exists
    if (current_zipfile === null) {
      current_zipfile = new JSZip();
      current_zipfile_size_MB = 0;
    }

    const downloaded_blob = (await fetch(data.src)).blob();
    current_zipfile.file(data.id + "_original." + data.file_format, downloaded_blob);
    current_zipfile_size_MB += downloaded_blob.size / 1000000;

    // early call to ZIP saving function
    if (current_zipfile_size_MB >= max_zip_file_size_MB) {
      await imagesDownloadDone(false);
      current_zipfile = null;
      current_zipfile_idx++;
      current_zipfile_size_MB = 0;
    }
  }

  async function imagesDownloadDone(reset = true) {
    await current_zipfile.generateAsync({type:"blob"})
                         .then(function (blob) {
                           saveAs(blob, "pharma_nam-download-" + current_zipfile_idx + ".zip");
                         });
    if (reset) {
      current_zipfile = null;
      current_zipfile_idx = 0;
      current_zipfile_size_MB = 0;
    }
  }

  async function handleDeleteCheckedPictures(confirmed=false) {
    if (!confirmed) {
      setConfirmDeleteModalState(deleteModal.showed);
      return;
    }

    let i;
    let pictures_copy = [...pictures];
    for (i = 0; i < checkedPicturesIdx.length; i++) {
      REST.delete("images/" + pictures[checkedPicturesIdx[i].idx].id);
      pictures_copy.splice(checkedPicturesIdx[i].idx, 1);
    }
    setCheckedPicturesIdx([]); 
    setPictures(pictures_copy);
  }

  useEffect(() => {
    if (confirmDeleteModalState == deleteModal.confirmed)
      handleDeleteCheckedPictures(true);
  }, [confirmDeleteModalState]);

  const deselect_btn = checkedPicturesIdx.length > 0 ? (<Button onClick={() => handleUnselectClicked()}>
                                                          <X size={20}/>
                                                          {"Déselectionner"}
                                                        </Button>) : null;

  const actions_btn = checkedPicturesIdx.length > 0 ? (<Dropdown>
                                                         <Dropdown.Toggle>
                                                           <ThreeDots size={20}/>
                                                         </Dropdown.Toggle>
                                                         <Dropdown.Menu>
                                                           <Dropdown.Item onClick={() => handleDownloadCheckedPictures()}>Télécharger</Dropdown.Item>
                                                           <Dropdown.Item onClick={() => handleDeleteCheckedPictures()}>Supprimer</Dropdown.Item>
                                                         </Dropdown.Menu>
                                                       </Dropdown>) : null;

  return (
    <>
      <ConfirmDeleteModal state={confirmDeleteModalState} setState={setConfirmDeleteModalState} text={"Êtes-vous de sûr de vouloir supprimer les éléments sélecionnés? Cette action est irréversible."}/>
      <input type="file"
             id="image-hidden-input"
             multiple="multiple"
             accept="image/png, image/jpeg"
             ref={imageHiddenInputRef}
             onChange={(x) => fileUploadInputChanged(x)}
             style={{display: "none"}}/>
      <Container className="col col-12 py-2 col-xl-9">
        <Stack direction="horizontal" gap={3} className='py-3'>
          <Button href={"/nos_souvenirs?view=albums"}>
            Tous les albums
          </Button>
          {(profile.role.name === 'admin' || profile.role.name === 'publisher') ?
           <Button onClick={() => showFilePicker()}>
             <Plus size="20"/>
             Ajouter Photos
           </Button>
           :
           null
          }
          <Button onClick={handleSelectAll}>
            <Check2All size={20}/>
          </Button>
          {deselect_btn}
          {actions_btn}
        </Stack>
      </Container>
      <Container className="overflow-scroll" style={{ minHeight: '10rem', maxHeight: '80vh'}}>
        <PhotoAlbum layout='rows'
                    spacing='1'
                    padding='3'
                    targetRowHeight='350'
                    width='100'
                    onClick={({ photo }) => setLightboxIndex(photo.index)}
                    photos={pictures}
                    renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                      <DisplayImage photo={photo}
                                    key={photo.id}
                                    wrapperStyle={wrapperStyle}
                                    renderDefaultPhoto={renderDefaultPhoto}
                                    checkedCallback={checkedCallback}/>
                    )}
        />
        <Lightbox
          slides={pictures}
          open={lightboxIndex >= 0}
          index={lightboxIndex}
          close={() => setLightboxIndex(-1)}
          plugins={[Slideshow, Thumbnails, Zoom, Download]}
        />
      </Container>
    </>
  );
}

function DisplayImage({photo, wrapperStyle, renderDefaultPhoto, checkedCallback})
{
  const [checked, setChecked] = useState(photo.checked);
  const [shown, setShown] = useState(false);

  useEffect(() => {
    setChecked(photo.checked);
  }, [photo.checked]);

  function handleCheck(value) {
    setChecked(value);
    checkedCallback(photo.index, value);
  }

  let checkmark = null;
  if (shown || checked) {

    checkmark = checked ?
      <div className="bg-white rounded-circle position-absolute" style={{top: '15px', right: '15px', width: '36px', height: '36px'}}>
        <Check2Circle onClick={() => handleCheck(false)} size={24} className="position-absolute" style={{top: '6px', left: '6px'}}/>
      </div>
    :
    <div className="bg-white rounded-circle position-absolute" style={{top: '15px', right: '15px', width: '36px', height: '36px'}}>
      <Circle onClick={() => handleCheck(true)} size={24} className="position-absolute" style={{top: '6px', left: '6px'}}/>
    </div>
  }

  return (
    <div style={wrapperStyle}
         className={checked ? "position-relative bg-primary" : "position-relative"}
         onMouseEnter={() => setShown(true)}
         onMouseLeave={() => setShown(false)}>
      {checkmark}
      {renderDefaultPhoto({ wrapped: true })}
    </div>
  );
}

function ConfirmDeleteModal({
  state,
  setState,
  text,
}) {

  async function handleClose(confirmed) {
    if (confirmed)
      setState(deleteModal.confirmed);
    else
      setState(deleteModal.hidden);
  }

  return (
    <Modal show={state == deleteModal.showed}>
      <Modal.Header closeButton onHide={() => handleClose(false)}>
        <Modal.Title>Confirmation de suppression</Modal.Title>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleClose(false)}>
          Annuler
        </Button>
        <Button variant="danger" onClick={() => handleClose(true)}>
          Supprimer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AlbumPage;

import React, { useState, useEffect } from 'react';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import { Placeholder, Dropdown, Stack } from 'react-bootstrap';
import REST from '../rest/pharma_nam';
import { Alert, Pagination } from 'react-bootstrap';
import { SearchBar } from '../components/searchbar';
import FailurePage from './FailurePage';

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

let isRegistrations = false;

function UsersPage() {
  isRegistrations = window.location.href.includes('registrations');
  var profile = REST.getProfile();
  if (!profile || profile.role.name !== 'admin')
    return <FailurePage/>;
  return (
    <Container className="col col-12 py-2 col-xl-12">
      <DisplayAllNonActiveUsers profile={profile} />
    </Container>
  );
}

function DisplayAllNonActiveUsers({profile}){
  const [users, setUsers] = useState([]);
  const [showAlert, setShowAlert] = useState({ 'success': null, 'msg': '' });
  const [pages, setPages] = useState([]);
  const [nbPages, setNbPages] = useState(0);
  const [active, setActive] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchTxt, setSearchTxt] = useState('');

  const itemsPerPage = 12;
  useEffect(() => {
    let tmpPages = [];
    for (let number = 1; number <= nbPages; number++) {
      tmpPages.push(
        <Pagination.Item onClick={() => {setActive(number); setIsLoaded(false);}} key={number} active={number === active}>
          {number}
        </Pagination.Item>,
      );
    }
    setPages(tmpPages);
    if(isLoaded === false)
    {
      loadUsers("", null); // active - 1 because we start counting pages at 1 and not 0
      setIsLoaded(true);
    }
  }, [nbPages, active]);

  return(
    <>
      <Container>
        <Alert show={showAlert.success} variant="success" className="mb-3 py-2 text-center">
          {showAlert.msg}
        </Alert>
        <Alert show={showAlert.success === false} variant="danger" className="mb-3 py-2 text-center">
          {showAlert.msg}
        </Alert>
        <div className='d-flex justify-content-center'>
          <SearchBar
            searchText={searchTxt}
            setSearchText={setSearchTxt}
            submitCallback={() => loadUsers(searchTxt, false)}
          />
        </div>
        {users}
        <div className='d-flex justify-content-center py-6'>
          <Pagination>{pages}</Pagination>
        </div>
      </Container>

    </>
  );

  async function loadUsers(query, graduationYear){

    const response =  await REST.getJSON(
      `profiles?offset=${active - 1}&itemsPerPage=${itemsPerPage}&query=${query ? query : ''}&gradYear=${graduationYear ? graduationYear : ''}` + (isRegistrations ? '&registrations=true' : '')
    );

    if(response !== null){

      const profiles = await Promise.all(
        response.profiles.map(async (profile) =>  <UserListTile key={profile.id} profile={profile} alertSetter={ (x) => setShowAlert(x)}/> )
      );
      const nbPages = Math.ceil(response.total / itemsPerPage);
      setNbPages(nbPages);

      setUsers(profiles);
    }
  }
}



function UserListTile({profile, alertSetter}) {

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
  
    return (
      
      <Row key={profile.id} className="py-2 justify-space-between">
        <Col>
          <Card>
            <Card.Body >
              <Card.Title>
                <Row className="d-flex justify-content-start gy-2">
                  <Col className="col-12">
                    {isRegistrations && <Badge bg="info">Non accepté</Badge>}
                    {!isRegistrations && <Badge bg={profile.role.name === "admin" ? "info" : "primary"}>{capitalizeFirstLetter(profile.role.name)}</Badge>}
                  </Col>
                  <Col className="col-12">
                    {profile.last_name}  {profile.first_name}
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text>
                {profile.description.slice(0,175)}...
              </Card.Text> 
              <Stack direction="horizontal" gap={2}>
                <Button href={"/profile/" + profile.id}>Voir profil</Button>
                {isRegistrations && <Button onClick={async () => await acceptProfile(profile, alertSetter)} >Accepter</Button>}
                {isRegistrations && <Button onClick={async () => await refuseProfile(profile, alertSetter)} >Refuser</Button>}
                {!isRegistrations && 
                <Col lg={2} xs={3} className=""><Dropdown >
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    Assigner un role
                  </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={async () => await assignRole(profile, (x) => alertSetter(x), "admin") }>Admin</Dropdown.Item>
                  <Dropdown.Item onClick={async () => await assignRole(profile, (x) => alertSetter(x), "publisher") }>Publisher</Dropdown.Item>
                  <Dropdown.Item onClick={async () => await assignRole(profile, (x) => alertSetter(x), "user") }>Utilisateur</Dropdown.Item>
                  <Dropdown.Item onClick={async () => await banUser(profile, (x) => alertSetter(x), "banned") }>Ban</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown></Col>}
            </Stack>
          </Card.Body>
        </Card>
      </Col>
    </Row>

  );
}

async function acceptProfile(profile, alertSetter){

  const response = await REST.postJSON(
    'user/admin/',
    {"id" : profile.id,"accept" : true}
  );

  if(response !== null){
    alertSetter({'success':true, 'msg': 'Utilisateur accepté!'});
    await sleep(1000);
    window.location.reload();
  }
  else{
    alertSetter({'success': false, 'msg': 'Erreur, veuillez ressayer'});
  }

}

async function refuseProfile(profile, alertSetter){

  const response = await REST.postJSON(
    'user/admin/',
    {"id" : profile.id,"refuse" : true}
  );

  if(response !== null){
    alertSetter({'success':true, 'msg': 'Utilisateur refusé!'});
    await sleep(1000);
    window.location.reload();
  }
  else{
    alertSetter({'success': false, 'msg': 'Erreur, veuillez ressayer'});
  }

}

async function assignRole(profile, alertSetter, role){
  const response = await REST.postJSON(
    'user/admin/',
    {"id" : profile.id,"assign" : true, "role": role}
  );
  
  if(response !== null){
    alertSetter({'success':true, 'msg': 'Rôle assigné!'});
    await sleep(1000);
    window.location.reload();
  }
  else{
    alertSetter({'success': false, 'msg': 'Erreur, veuillez ressayer'});
  }
}

async function banUser(profile, alertSetter){
  const response = await REST.postJSON(
    'user/admin/',
    {"id" : profile.id,"ban" : true}
  );
  
  if(response !== null){
    alertSetter({'success':true, 'msg': 'Utilisateur banni!'});
    await sleep(1000);
    window.location.reload();
  }
  else{
    alertSetter({'success': false, 'msg': 'Erreur, veuillez ressayer'});
  }
}





function getPlaceholder(i) { //if no key is provided, the following error is triggered ('Warning: Each child in a list should have a unique "key" prop.')
  return (
    <Row key={i} className="py-2">
      <Col>
        <Card>
          <Card.Body>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={4}/>
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={6}/>
            </Placeholder>
            <Placeholder.Button variant="primary" animation="glow" xs={2}/>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default UsersPage;

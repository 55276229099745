import { Row, Image, Stack } from "react-bootstrap";
import React, { useState, useEffect } from "react"
import REST from '../rest/pharma_nam';
import { SocialIcon } from "react-social-icons";

function Footer(){ 

    const [sponsors, setSponsors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function loadSponsors(){
            const response = await REST.getJSONNoAuth('sponsors');
            setIsLoading(false);
            if(response !== null){
                const loadedSponsors = response.map((x) => <a key={x.name} href={x.linkUrl}><Image alt={x.name} className='px-2 py-2' style={{height:'70px'}} src={x.imgUrl}></Image></a>);                
                setSponsors(loadedSponsors);
            }
        }

        loadSponsors();
    }, []);
    if(isLoading){
        return (
            <></>
        );
    }
    return (
        <footer className="page-footer footer--pin font-small blue pt-4 fixed-bottom" style={{backgroundColor:'#6E859A', position:'relative', bottom:'0px'}}>
            <div className="container-fluid text-center text-md-left">
                <div className="row">
                    <div className="col-md-4 mt-md-0 mt-3">
                        <h5 className="text-uppercase">Sponsors</h5>
                        <Row>
                            <div className="py-3">
                                { sponsors }
                            </div>
                        </Row>
                    </div>

                    <hr className="clearfix w-100 d-md-none pb-0"/>
                    <div className="col-md-4 mb-md-0 mb-3">
                        <h5 className="text-uppercase">A propos du site</h5>
                        <ul className="list-unstyled">
                            <p>
                                Ce site est le site officiel de l'asbl de la faculté pharmaceutique de l'université de namur. Ce site est le site officiel de l'asbl de la faculté pharmaceutique de l'université de namur.
                                Ce site est le site officiel de l'asbl de la faculté pharmaceutique de l'université de namur. Ce site est le site officiel de l'asbl de la faculté pharmaceutique de l'université de namur.
                            </p>
                        </ul>
                    </div>
                    <div className="col-md-3 mb-md-0 mx-2 mb-3">
                        <Stack><h5>Nos réseaux</h5>
                            <div className="d-flex justify-content-center py-2 gap-2">
                                <SocialIcon href="https://twitter.com/unamur" url="www.twitter.com"/>
                                <SocialIcon href="https://www.linkedin.com/school/unamur/" url="www.linkedin.com"/>
                                <SocialIcon href="https://www.instagram.com/universitedenamur/" url="www.instagram.com"/>
                                <SocialIcon href="https://www.facebook.com/UniversitedeNamur/" url="www.facebook.com"/>
                            </div>
                        </Stack>
                    </div>
                </div>
            </div>

            <div className="footer-copyright text-center py-3">© 2023 Copyright:
                PharmaNam.be
            </div>

        </footer>
    );
}


export default Footer;
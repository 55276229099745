import React, { useState, useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import Alert from 'react-bootstrap/Alert';

import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import 'react-quill/dist/quill.snow.css';

import imageCompression from 'browser-image-compression';

import REST from '../rest/pharma_nam';
import { datetime_to_fr_short_date } from '../models/Models';

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);

function EditArticlePage() {
  const articleDefaultValues = {
    'title': '',
    'content': '',
    'preview': '',
    'author': '', // Get from Login info
  };

  // contains the article object
  const [article, setArticle] = useState(articleDefaultValues);
  // enable or disable the save button
  const [articleModified, setArticleModified] = useState(false);
  // switch from editing mode to preview mode
  const [preview, setPreview] = useState(false);
  // confirm delete dialog
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  // used to know what the toggle published state button does (Publish or Hide)
  const [publishedState, setPublishedState] = useState(false);
  // used to show a toast message for instance when saving the article
  const [showToast, setShowToast] = useState({show: false, msg: "", variant: "sucess"});

  const [coverPicture, setCoverPicture] = useState(null);
  const [previousCoverPicture, setPreviousCoverPicture] = useState(null);

  // we need to save the uploaded file in a DataTransfer object
  // so we can reset it on re-render
  const [dataTransfer, setDataTransfer] = useState(new DataTransfer());

  // if we are editing an article, fetch it
  // otherwise we are creating a new one
  let article_id = useParams().id;
  useEffect(() => {
    async function getArticle() {
      let response = await REST.getJSON("articles/" + article_id);
      if (response) {
        setPublishedState(response.published);

        if (response.cover) {
          setCoverPicture(response.cover.url)
        }
      }
      setArticle(response);
    }
    if (article_id) {
      getArticle();
    }
  }, []);

  function previewSwitchChanged(event) {
    setPreview(event.target.checked);
  }

  // check the fetching was OK or it is a new article otherwise show error
  if ((article_id && article) || article_id == null) {
    return (
      <Container className="col col-12 py-2 col-xl-7">
        <Row className="py-2">
          <Col className="col-12 col-sm-10">
            <h3>Rédiger un article</h3>
          </Col>
          <Col className="mx-end col-12 col-sm-2 justify-content-sm-end justify-content-begin align-items-center d-flex">
            <Form.Check
              disabled={false}
              type="switch"
              label="Preview"
              onChange={(x) => previewSwitchChanged(x)}
            />
          </Col>
        </Row>
        {preview ?
         <Preview
           key="preview"
           articleGetter={article}
           coverPicture={coverPicture}/>
         :
         <Editor key="editor"
                 article={article}
                 setArticle={setArticle}
                 publishedState={publishedState}
                 setPublishedState={setPublishedState}
                 setShowDeleteDialog={setShowDeleteDialog}
                 articleModified={articleModified}
                 setArticleModified={setArticleModified}
                 setShowToast={setShowToast}
                 previousCoverPicture={previousCoverPicture}
                 setPreviousCoverPicture={setPreviousCoverPicture}
                 coverPicture={coverPicture}
                 setCoverPicture={setCoverPicture}
                 dataTransfer={dataTransfer}
                 setDataTransfer={setDataTransfer}/>
        }
        <ConfirmDelete
          show={showDeleteDialog}
          showSetter={(x) => setShowDeleteDialog(x)}
          article={article}
        />

        <ToastContainer position="top-center" className="my-3 position-fixed">
          <Toast onClose={() => setShowToast({show: false})}
                 show={showToast.show}
                 delay={3000}
                 autohide
                 bg={showToast.variant}
                 className="text-center">
            <Toast.Body>{showToast.msg}</Toast.Body>
          </Toast>
        </ToastContainer>
      </Container>
    );
  }
  else {
    return (
      <Container className="col col-12 py-2 col-xl-7">
        <Alert variant="danger">
          Article introuvable...
        </Alert>
      </Container>
    );
  }
}

function Editor ({
  article,
  setArticle,
  publishedState,
  setPublishedState,
  setShowDeleteDialog,
  articleModified,
  setArticleModified,
  setShowToast,
  coverPicture,
  setCoverPicture,
  previousCoverPicture,
  setPreviousCoverPicture,
  dataTransfer,
  setDataTransfer,
})
{
  // should the article have a cover picture?
  const [coverPictureEnabled, setCoverPictureEnabled] = useState(false);

  // has the picture been modified?
  const [pictureModified, setPictureModified] = useState(false);

  // reference to the file input (cover picture)
  const fileRef = useRef(null);


  useEffect(() => {
    setCoverPictureEnabled(article.cover && coverPicture);
  }, [coverPicture]);

  useEffect(() => {
    if (dataTransfer.files.length) {
      fileRef.current.files = dataTransfer.files;
      setPictureModified(true);
    }
  }, []);

  // reference to QuillJS object
  var quillRef = React.useRef();

  // modules used in QuillJS (to move in another file)
  var modules = {
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize'],
    },
    imageCompress: {
      quality: 0.7,
      maxWidth: 1200,
      maxHeight: 1200,
      imageType: 'image/jpeg',
      debug: true,
      suppressErrorLogging: false,
      insertIntoEditor: undefined,
    },
    toolbar: [
      [{ 'header': [false, 2, 1] }],
      ['bold', 'italic', 'underline','strike', 'blockquote', 'alignment'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      [{ 'align': [] }],
      [{ 'color': [] }, { 'background': [] }],
      ['clean']
    ],
  };

  function titleChanged(event) {
    setArticle((getter) => ({ ...getter, ['title']: event.target.value }));
    setArticleModified(true);
  }

  function editorContentChanged(value) {
    setArticle((getter) => ({ ...getter, ['content']: value }));
    setArticleModified(true);
  }

  async function handleSaveArticle() {
    if (typeof quillRef.getEditor !== 'function') return;
    const editor = quillRef.getEditor();
    const unprivilegedEditor = quillRef.makeUnprivilegedEditor(editor);

    article.preview = unprivilegedEditor.getText().substring(0, 200);
    article.content = unprivilegedEditor.getHTML();

    let resp;
    if (article.id) {
      resp = await updateArticle(article, article.id);
    }
    else {
      resp = await createArticle(article);
    }
    // update article object with server response
    if (resp) {
      // if new article, change the url from new_article to edit_article
      if (!article.id) {
        window.location.href = '/edit_article/' + resp.id;
      }
      setArticle(resp);
    }

    // check if the cover picture needs to be created, modified or deleted
    // creation
    if (coverPicture && article.cover === null && pictureModified && previousCoverPicture === null) {
      const imgCreationResponse = await REST.postFile("articles/" + article.id + "/cover/",
                                                      dataTransfer.files[0],
                                                      dataTransfer.files[0].name,
                                                     );
      if (imgCreationResponse) {
        setArticle((getter) => ({ ...getter, ['cover']: imgCreationResponse}));
        setPreviousCoverPicture(null);
        setPictureModified(false);
        setCoverPictureEnabled(true);

        fileRef.current.value = "";
        dataTransfer.clearData();
        setDataTransfer(new DataTransfer());
      }
    }
    // modification
    else if (article.cover && pictureModified && previousCoverPicture){

      const imgUpdateResponse = await REST.putFile("articles/" + article.id + "/cover/",
                                                   dataTransfer.files[0],
                                                   dataTransfer.files[0].name,
                                                  );
      if (imgUpdateResponse) {
        setArticle((getter) => ({ ...getter, ['cover']: imgUpdateResponse}));
        setPreviousCoverPicture(null);
        setPictureModified(false);
        setCoverPictureEnabled(true);

        fileRef.current.value = "";
        dataTransfer.clearData();
        setDataTransfer(new DataTransfer());
      }
    }

    // deletion
    else if (coverPicture === null && !coverPictureEnabled && previousCoverPicture) {
      const imgDeleteResponse = await REST.delete("articles/" + article.id + "/cover/");
      if (imgDeleteResponse) {
        setArticle((getter) => ({ ...getter, ['cover']: null}));
        setCoverPictureEnabled(false);
        setPreviousCoverPicture(null);
        setPictureModified(false);
        setCoverPicture(null);
      }
    }

    if (resp !== null) {
      setArticleModified(false);
      setShowToast({show: true, msg: "Document sauvegardé", variant: "primary"})
    }
    else {
      setShowToast({show: true, msg: "Erreur de sauvegarde", variant: "danger"})
      setArticleModified(false);
    }
  }

  function handlePublish() {
    article.published = true;
    handleSaveArticle();
    setPublishedState(true);
  }

  function handleHide() {
    article.published = false;
    handleSaveArticle();
    setPublishedState(false);
  }

  // TODO verify OK
  async function createArticle(article) {
    let response = await REST.postJSON('articles/', article);
    return response;
  }

  async function updateArticle(article, id) {
    let response = await REST.patchJSON('articles/' + id + '/', article);
    return response;
  }

  // multiple cases with the cover picture:
  // - this is a new article, there's no existing cover image, if the user choose
  // a file as the cover picture, the switch will be checked but disabled. If the action is cancelled,
  // the switch is unchecked and disabled.
  // - the article is an existing article being modified without a cover picture.
  // - the article is an existing article being modified with a cover picture. Then the switch is checked and enabled
  // so the user can remove the existing cover picture or modify it by providing a file. Once a file is provided
  // the switch is disabled, the only to re enable it is to cancel the action.
  function handleCoverPictureChanged(event) {
    if (!event) {
      setPictureModified(false);
      fileRef.current.value = "";
      dataTransfer.clearData();
      setDataTransfer(new DataTransfer());

      setCoverPicture(previousCoverPicture);
      setPreviousCoverPicture(null);
      setCoverPictureEnabled(coverPicture != null);
      setArticleModified(false);
    }
    else if (event.target.files && event.target.files[0]) {
      // save file upload states
      // compress image
      compressImg(event.target.files[0]).then((x) => {
        dataTransfer.items.add(new File([x], event.target.files[0].name));
        fileRef.current.files = dataTransfer.files;
      });

      setPreviousCoverPicture(coverPicture);
      setCoverPicture(URL.createObjectURL(event.target.files[0]));
      setPictureModified(true);
      setArticleModified(true);
      setCoverPictureEnabled(true);
    }
  }

  function coverPictureCheckedChanged(event) {
    if (event.target.checked) {
      if (coverPicture === null) {
        setCoverPicture(previousCoverPicture);
      }
    }
    else {
      setPreviousCoverPicture(coverPicture);
      setCoverPicture(null);
    }
    setCoverPictureEnabled(event.target.checked);
    setArticleModified(true);
  }

  async function compressImg(file) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1200
    }
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="px-3 py-3 border rounded">
      <Form>
        <Row className="gy-3 pb-2">
          <Col className="col-12 col-md-6">
            <Form.Label>Titre</Form.Label>
            <Form.Control
              key="title-form"
              type='text'
              onChange={(x) => titleChanged(x)}
              value={article.title}/>
          </Col>
          <Col className="col-12 col-md-6">
            <Row>
              <Col className="col-auto">
                <Form.Label>Image de couverture</Form.Label>
              </Col>
              <Col className="col-6">
                <Form.Check
                  disabled={(coverPicture === null && previousCoverPicture === null ) || pictureModified === true}
                  type="switch"
                  checked={coverPictureEnabled}
                  onChange={(x) => coverPictureCheckedChanged(x)}
                />
              </Col>
            </Row>
            {/* file input wont survive on re render */}
            <div className="input-group">
              <input type="file"
                     ref={fileRef}
                     key={"coverPictureInput"}
                     onChange={(x) => handleCoverPictureChanged(x)}
                     className="form-control"/>
              <Button disabled={!pictureModified} onClick={() => handleCoverPictureChanged(null)} variant="outline-secondary">Annuler</Button>
            </div>
          </Col>
        </Row>
      </Form>
      <ReactQuill
        key="editor1"
        className="py-3"
        theme="snow"
        modules={modules}
        value={article.content}
        onChange={(x) => editorContentChanged(x)}
        ref={(el) => {
          quillRef = el;
        }}/>
      <Stack direction="horizontal" gap={2} className='d-flex justify-content-end'>
        <Button variant="danger" onClick={() => setShowDeleteDialog(true)}>Supprimer</Button>
        <Button onClick={handleSaveArticle} variant="secondary" disabled={!articleModified} >Sauvegarder</Button>
        {publishedState ?
         <Button onClick={handleHide} variant="warning">Cacher</Button>
         :
         <Button onClick={handlePublish} variant="primary">Publier</Button>
        }
      </Stack>
    </div>
  );
}


function Preview ({
  articleGetter,
  coverPicture,
}) {
  var author = REST.getProfile();
  const date = new Date(articleGetter.createdAt);
  return (
    <>
      <Row>
        <Col>
          <Stack direction='horizontal' gap='3' className="mt-2 py-2 px-3 border rounded d-inline-flex">
            <h5 className="text-secondary ">{author.first_name} {author.last_name}</h5>
            <div className="vr"/>
            <h5 className="text-secondary">{ datetime_to_fr_short_date(new Date(date))}</h5>
          </Stack>
        </Col>
      </Row>
      <div className="py-3 h1 text-wrap text-break text-center text-xl-start bd-highlight">
        {articleGetter.title}
      </div>
      {coverPicture &&
       <div className="col mx-auto col-xxl-10">
         <img src={coverPicture}
              alt="Cover"
              className="object-fit-none w-100 rounded-3 border mx-auto d-block mb-2"
         />
       </div>
      }
      <div className="publication col mx-auto col-xxl-9 ql-editor-viewer"
           dangerouslySetInnerHTML={{ __html: articleGetter.content }}></div>
    </>
  );
}

function ConfirmDelete({
  show,
  showSetter,
  article,
}) {

  async function handleClose(confirmed) {
    showSetter(false);
    if (!confirmed) { return; }

    let resp = await REST.delete('articles/' + article.id + '/');
    if (resp) {
      window.location = '/articles';
    }
  }

  return (
    <Modal show={show}>
      <Modal.Header closeButton onHide={() => handleClose(false)}>
        <Modal.Title>Confirmation de suppression</Modal.Title>
      </Modal.Header>
      <Modal.Body>Êtes-vous de sûr de vouloir supprimer cet article? Cette action est irréversible.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleClose(false)}>
          Annuler
        </Button>
        <Button variant="danger" onClick={() => handleClose(true)}>
          Supprimer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditArticlePage;

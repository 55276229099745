import React, { useState, useEffect } from 'react';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import { Placeholder } from 'react-bootstrap';
import { ControlToolbar } from '../components/publications-common';

import { EventsSearchBar } from '../components/searchbar';
import FailurePage from './FailurePage';

import REST from '../rest/pharma_nam'
import { sort_order, datetime_to_fr_short_date } from '../models/Models';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function EventsPage() {

  const default_search_query = {
    'search_text': '',
    'sort_by_date': '',
    'include_past': '',
  };

  const [queryParameters, setQueryParameters] = useState(default_search_query);

  function onSearch(searchText, sortByDate, includePast) {
    let query = {};
    query.search_text = searchText;
    query.sort_by_date = sortByDate;
    query.include_past = includePast;

    setQueryParameters(query);
  }

  var profile = REST.getProfile();
  return (
    <Container className="col col-12 py-2 col-xl-8">
      {(profile.role.name === 'admin' || profile.role.name === 'publisher') ?
       <ControlToolbar
         new_publication_str="Nouvel Évènement"
         link_str="new_event"/>
       :
       null
      }

      <EventsSearchBar className="mx-0" onSearch={(searchText, sortByDate, includePast) => onSearch(searchText, sortByDate, includePast)} />
      <DisplayAllEvents profile={profile} queryParameters={queryParameters}/>
    </Container>
  );
}

function DisplayAllEvents({
  profile,
  queryParameters,
}) {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    var fetchDone = false;
    async function startFetching() {

      let query_str = "events?include_content=false";
      if (queryParameters.search_text !== "") {
        query_str += "&search_text=" + escape(queryParameters.search_text);
      }
      if (queryParameters.sort_by_date !== "") {
        query_str += "&sort_by_date=" + (queryParameters.sort_by_date == sort_order.asc ? "asc" : "desc");
      }
      if (queryParameters.include_past !== "") {
        query_str += "&include_past=" + queryParameters.include_past;
      }

      let response;
      if (profile.role.name === 'anonymous')
        response = await REST.getJSONNoAuth(query_str);
      else
        response = await REST.getJSON(query_str);

      const jsonData = response;
      fetchDone = true;
      const fetched = jsonData.map(val => <Event key={val.id} event={val} profile={profile}/>);
      setEvents(fetched);

      const timer = sleep(100);
      timer.then( (x) => {
        if (!fetchDone) {
          let placeholder = [];
          for (let i = 0; i < 10; i++) {
            placeholder.push(getPlaceholder());
          }
          setEvents(placeholder);
        }
      });
    }

    startFetching();
  }, [queryParameters, ]);


  return(
    <div className="d-flex flex-column flex-grow-1">
      {events}
    </div>
  );
}

function getPlaceholder() {
  return (
    <Row className="py-2">
      <Col>
        <Card>
          <Row className="no-gutters">
            <Col className="col-4">
            </Col>
            <Col className="col-8">
              <Card.Body>
                <Placeholder as={Card.Title} animation="glow">
                  <Placeholder xs={4}/>
                </Placeholder>
                <Placeholder as={Card.Text} animation="glow">
                  <Placeholder xs={6}/>
                </Placeholder>
                <Placeholder.Button variant="primary" animation="glow" xs={2}/>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}

function Event({
  event,
  profile
})
{
  const [img, setImg] = useState(null);

  useEffect(() => {
    async function fetchImage(cover) {
        setImg(cover.url);
    }

    if (event.cover) {
      fetchImage(event.cover);
    }

  }, []);

  return (
    <Row className="py-2 ">
      <Card >
        <Card.Body>
          <Row className="">
            <Col className={event.cover ? "col-lg-8 col-12 " : "col-12"}>
              <Card.Title className="">
                <Row className="d-flex flex-column flex-grow-1 justify-content-start gy-2 ">
                  <Col className="col-12 d-flex">
                    {!event.published && <Badge bg="secondary">Brouillon</Badge>}
                    <Badge className="me-1" bg="secondary">{datetime_to_fr_short_date(new Date(event.startDateTime))}</Badge>
                    <Badge className="me-1" bg="secondary">{event.location}</Badge>
                  </Col>
                  <Col className="col-12">
                    {event.title}
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text>
                {event.preview}...
              </Card.Text>
              {event.published ?
               <Button href={"/event/" + event.id} variant="primary">Voir l'évènement</Button>
               :
               <Button href={"/edit_event/" + event.id} variant="primary">Modifier</Button>
              }
            </Col>

            {event.cover &&
             <Col className="col-lg-4 col-12 overflow-hidden  mt-2 mt-lg-0">
               <div className="ratio ratio-16x9 rounded-3" style={{backgroundImage: `url(${img})`,
                                                                   backgroundPosition: "50% 50%",
                                                                   backgroundSize: "100% auto"
                                                                  }}/>
             </Col>
            }
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
}

export default EventsPage;

import React, { useState, useRef, useEffect, forwardRef, createRef } from 'react';
import { useParams } from "react-router-dom";
import CustomTooltip from "../components/tooltip";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { Calendar2Event } from "react-bootstrap-icons";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import 'react-quill/dist/quill.snow.css';

import imageCompression from 'browser-image-compression';

import REST from '../rest/pharma_nam';
import { DateNames, datetime_range_to_string } from '../models/Models';

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);

const saveEventErrorMsg = "Veuillez d'abord sauvegarder l'évenement";

function EditEventPage() {
  const eventDefaultValues = {
    'title': '',
    'content': '',
    'preview': '',
    'author': '', // Get from Login info
    'startDateTime': null,
    'endDateTime': null,
    'location': '',
    'form': null,
    'album': null,
  };

  // contains the event object
  const [event, setEvent] = useState(eventDefaultValues);
  // enable or disable the save button
  const [eventModified, setEventModified] = useState(false);
  // switch from editing mode to preview mode
  const [preview, setPreview] = useState(false);
  // confirm delete dialog
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  // used to know what the toggle published state button does (Publish or Hide)
  const [publishedState, setPublishedState] = useState(false);
  // used to show a toast message for instance when saving the event
  const [showToast, setShowToast] = useState({show: false, msg: "", variant: "sucess"});

  const [coverPicture, setCoverPicture] = useState(null);
  const [previousCoverPicture, setPreviousCoverPicture] = useState(null);

  // we need to save the uploaded file in a DataTransfer object
  // so we can reset it on re-render
  const [dataTransfer, setDataTransfer] = useState(new DataTransfer());

  // if we are editing an event, fetch it
  // otherwise we are creating a new one
  let event_id = useParams().id;
  useEffect(() => {
    async function getEvent() {
      let response = await REST.getJSON("events/" + event_id);
      if (response) {
        setPublishedState(response.published);

        if (response.cover) {
          setCoverPicture(response.cover.url)
        }
      }
      setEvent(response);
    }
    if (event_id) {
      getEvent();
    }
  }, []);

  function previewSwitchChanged(event) {
    setPreview(event.target.checked);
  }

  // check the fetching was OK or it is a new event otherwise show error
  if ((event_id && event) || event_id == null) {
    return (
      <Container className="col col-12 py-2 col-xl-7">
        <Row className="py-2">
          <Col className="col-12 col-sm-10">
            <h3>Création d'un évènement</h3>
          </Col>
          <Col className="mx-end col-12 col-sm-2 justify-content-sm-end justify-content-begin align-items-center d-flex">
            <Form.Check
              disabled={false}
              type="switch"
              label="Preview"
              onChange={(x) => previewSwitchChanged(x)}
            />
          </Col>
        </Row>
        {preview ?
         <Preview
           key="preview"
           eventGetter={event}
           coverPicture={coverPicture}/>
         :
         <Editor key="editor"
                 event={event}
                 setEvent={setEvent}
                 publishedState={publishedState}
                 setPublishedState={setPublishedState}
                 setShowDeleteDialog={setShowDeleteDialog}
                 eventModified={eventModified}
                 setEventModified={setEventModified}
                 setShowToast={setShowToast}
                 previousCoverPicture={previousCoverPicture}
                 setPreviousCoverPicture={setPreviousCoverPicture}
                 coverPicture={coverPicture}
                 setCoverPicture={setCoverPicture}
                 dataTransfer={dataTransfer}
                 setDataTransfer={setDataTransfer}/>
        }
        <ConfirmDelete
          show={showDeleteDialog}
          showSetter={(x) => setShowDeleteDialog(x)}
          event={event}
        />

        <ToastContainer position="top-center" className="my-3 position-fixed">
          <Toast onClose={() => setShowToast({show: false})}
                 show={showToast.show}
                 delay={3000}
                 autohide
                 bg={showToast.variant}
                 className="text-center">
            <Toast.Body>{showToast.msg}</Toast.Body>
          </Toast>
        </ToastContainer>
      </Container>
    );
  }
  else {
    return (
      <Container className="col col-12 py-2 col-xl-7">
        <Alert variant="danger">
          Évènement introuvable...
        </Alert>
      </Container>
    );
  }
}

function Editor ({
  event,
  setEvent,
  publishedState,
  setPublishedState,
  setShowDeleteDialog,
  eventModified,
  setEventModified,
  setShowToast,
  coverPicture,
  setCoverPicture,
  previousCoverPicture,
  setPreviousCoverPicture,
  dataTransfer,
  setDataTransfer,
})
{
  // should the event have a cover picture?
  const [coverPictureEnabled, setCoverPictureEnabled] = useState(false);

  // has the picture been modified?
  const [pictureModified, setPictureModified] = useState(false);

  // reference to the file input (cover picture)
  const fileRef = useRef(null);


  useEffect(() => {
    setCoverPictureEnabled(event.cover && coverPicture);
  }, [coverPicture]);

  useEffect(() => {
    if (dataTransfer.files.length) {
      fileRef.current.files = dataTransfer.files;
      setPictureModified(true);
    }
  }, []);

  // reference to QuillJS object
  var quillRef = React.useRef();

  // modules used in QuillJS (to move in another file)
  var modules = {
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize'],
    },
    imageCompress: {
      quality: 0.7,
      maxWidth: 1200,
      maxHeight: 1200,
      imageType: 'image/jpeg',
      debug: true,
      suppressErrorLogging: false,
      insertIntoEditor: undefined,
    },
    toolbar: [
      [{ 'header': [false, 2, 1] }],
      ['bold', 'italic', 'underline','strike', 'blockquote', 'alignment'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      [{ 'align': [] }],
      [{ 'color': [] }, { 'background': [] }],
      ['clean']
    ],
  };

  function titleChanged(event) {
    setEvent((getter) => ({ ...getter, ['title']: event.target.value }));
    setEventModified(true);
  }

  function startDateChanged(date) {
    let old_start_date_time = null;
    if (event.startDateTime)
      old_start_date_time = new Date(event.startDateTime);

    setEvent((getter) => ({ ...getter, ['startDateTime']: date }));

    if (event.endDateTime && date && old_start_date_time)
    {
      // keep the delta between start and end
      let delta = new Date(event.endDateTime).getTime() - old_start_date_time.getTime();
      let new_end_date = new Date(date.getTime() + delta);
      endDateChanged(new_end_date);
    }
    setEventModified(true);
  }
  
  function endDateChanged(date) {
    let start_date = new Date(event.startDateTime);
    let continue_ = true;
    if (date && date < start_date)
    {
      if (date.getYear() < start_date.getYear())
        date.setYear(start_date.getYear());
      if (date.getMonth() < start_date.getMonth())
        date.setMonth(start_date.getMonth());
      if (date.getDate() < start_date.getDate())
        date.setDate(start_date.getDate());
      if (date.getHours() < start_date.getHours())
        date.setHours(start_date.getHours());
      if (date.getMinutes() < start_date.getMinutes())
        date.setMinutes(start_date.getMinutes());
    }
    setEvent((getter) => ({ ...getter, ['endDateTime']: date }));
    setEventModified(true);
  }

  function locationChanged(event) {
    setEvent((getter) => ({ ...getter, ['location']: event.target.value }));
    setEventModified(true);
  }

  function editorContentChanged(value) {
    setEvent((getter) => ({ ...getter, ['content']: value }));
    setEventModified(true);
  }

  async function createGoogleForm()
  {
    let id = event.id;
    if(!event.id) {
      setShowToast({show: true, msg: saveEventErrorMsg, variant: "info"});
      return;
    }
    const response = await REST.postJSON(
      "events/" + id + "/form/"
    );

    if(response === null){
      setShowToast({show: true, msg: "Erreur, veuillez signaler à un administrateur", variant: "danger"})
    }
    else {
      window.open(response);
    }
  }

  async function editGoogleForm()
  {
    const response = await REST.getJSON(
      "events/" + event.id + "/form"
    );

    if(response === null){
      setShowToast({show: true, msg: "Erreur, veuillez signaler à un administrateur", variant: "danger"})
    }
    else {
      var win = window.open(response);
      win.focus();
    }
  }

  async function createAlbum()
  {
    const response = await REST.postJSON(
      "events/" + event.id + "/album/"
    );

    if(response === null){
      setShowToast({show: true, msg: "Erreur lors de la création de l'album", variant: "danger"})
    }
    else
    {
      setShowToast({show: true, msg: "Album créé avec succès", variant: "primary"})
      setEvent((getter) => ({ ...getter, ['album']: response}));
    }
  }

  async function handleSaveEvent() {
    if (typeof quillRef.getEditor !== 'function') return;
    const editor = quillRef.getEditor();
    const unprivilegedEditor = quillRef.makeUnprivilegedEditor(editor);

    event.preview = unprivilegedEditor.getText().substring(0, 200);
    event.content = unprivilegedEditor.getHTML();

    let resp;
    if (event.id) {
      resp = await updateEvent(event, event.id);
    }
    else {
      resp = await createEvent(event);
    }
    // update event object with server response
    if (resp) {
      // if new event change the url from new_event to edit_event
      if (!event.id) {
        window.location.href = '/edit_event/' + resp.id;
      }
      setEvent(resp);
    } else {
      setShowToast({show: true, msg: "Erreur, Veuillez vérifier vos entrées", variant: "danger"});
      return;
    }
    
    // check if the cover picture needs to be created, modified or deleted
    // creation
    if (coverPicture && event.cover === null && pictureModified && previousCoverPicture === null) {
      const imgCreationResponse = await REST.postFile("events/" + event.id + "/cover/",
                                                      dataTransfer.files[0],
                                                      dataTransfer.files[0].name,
                                                     );
      if (imgCreationResponse) {
        setEvent((getter) => ({ ...getter, ['cover']: imgCreationResponse}));
        setPreviousCoverPicture(null);
        setPictureModified(false);
        setCoverPictureEnabled(true);

        fileRef.current.value = "";
        dataTransfer.clearData();
        setDataTransfer(new DataTransfer());
      }
    }
    // modification
    else if (event.cover && pictureModified && previousCoverPicture){

      const imgUpdateResponse = await REST.putFile("events/" + event.id + "/cover/",
                                                   dataTransfer.files[0],
                                                   dataTransfer.files[0].name,
                                                  );
      if (imgUpdateResponse) {
        setEvent((getter) => ({ ...getter, ['cover']: imgUpdateResponse}));
        setPreviousCoverPicture(null);
        setPictureModified(false);
        setCoverPictureEnabled(true);

        fileRef.current.value = "";
        dataTransfer.clearData();
        setDataTransfer(new DataTransfer());
      }
    }

    // deletion
    else if (coverPicture === null && !coverPictureEnabled && previousCoverPicture) {
      const imgDeleteResponse = await REST.delete("events/" + event.id + "/cover/");
      if (imgDeleteResponse) {
        setEvent((getter) => ({ ...getter, ['cover']: null}));
        setCoverPictureEnabled(false);
        setPreviousCoverPicture(null);
        setPictureModified(false);
        setCoverPicture(null);
      }
    }

    if (resp !== null) {
      setEventModified(false);
      setShowToast({show: true, msg: "Document sauvegardé", variant: "primary"})
    }
    else {
      setShowToast({show: true, msg: "Erreur de sauvegarde", variant: "danger"})
      setEventModified(false);
    }
  }

  function handlePublish() {
    event.published = true;
    handleSaveEvent();
    setPublishedState(true);
  }

  function handleHide() {
    event.published = false;
    handleSaveEvent();
    setPublishedState(false);
  }

  // TODO verify OK
  async function createEvent(event) {
    let response = await REST.postJSON('events/', event);
    return response;
  }

  async function updateEvent(event, id) {
    let response = await REST.patchJSON('events/' + id + '/', event);
    return response;
  }

  // multiple cases with the cover picture:
  // - this is a new event, there's no existing cover image, if the user choose
  // a file as the cover picture, the switch will be checked but disabled. If the action is cancelled,
  // the switch is unchecked and disabled.
  // - the event is an existing event being modified without a cover picture.
  // - the event is an existing event being modified with a cover picture. Then the switch is checked and enabled
  // so the user can remove the existing cover picture or modify it by providing a file. Once a file is provided
  // the switch is disabled, the only to re enable it is to cancel the action.
  function handleCoverPictureChanged(event) {
    if (!event) {
      setPictureModified(false);
      fileRef.current.value = "";
      dataTransfer.clearData();
      setDataTransfer(new DataTransfer());

      setCoverPicture(previousCoverPicture);
      setPreviousCoverPicture(null);
      setCoverPictureEnabled(coverPicture != null);
      setEventModified(false);
    }
    else if (event.target.files && event.target.files[0]) {
      // save file upload states
      // compress image
      compressImg(event.target.files[0]).then((x) => {
        dataTransfer.items.add(new File([x], event.target.files[0].name));
        fileRef.current.files = dataTransfer.files;
      });

      setPreviousCoverPicture(coverPicture);
      setCoverPicture(URL.createObjectURL(event.target.files[0]));
      setPictureModified(true);
      setEventModified(true);
      setCoverPictureEnabled(true);
    }
  }

  function coverPictureCheckedChanged(event) {
    if (event.target.checked) {
      if (coverPicture === null) {
        setCoverPicture(previousCoverPicture);
      }
    }
    else {
      setPreviousCoverPicture(coverPicture);
      setCoverPicture(null);
    }
    setCoverPictureEnabled(event.target.checked);
    setEventModified(true);
  }

  async function compressImg(file) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1200
    }
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }

  const startDateRef = React.createRef();
  const endDateRef = React.createRef();
  const DateCustomInput = forwardRef(({ value, onClick, title, onCancel}, ref) => (
    <FormGroup>
      <Form.Label>{title}</Form.Label>
      <div className="input-group">
        <input type="text"
               ref={ref}
               key={"coverPictureInput"}
               value={value}
               onClick={onClick}
               className="form-control"/>
        <Button variant="outline-secondary" onClick={() => onCancel(null)}>X</Button>
      </div>
    </FormGroup>
  ));


  return (
    <div className="px-3 py-3 border rounded">
      <Form>
        <Row className="gy-3 pb-2">
          <Col className="col-12 col-md-6">
            <Form.Label>Titre</Form.Label>
            <Form.Control
              key="title-form"
              type='text'
              onChange={(x) => titleChanged(x)}
              value={event.title}/>
          </Col>
          <Col className="col-12 col-md-6">
            <Row>
              <Col className="col-auto">
                <Form.Label>Image de couverture</Form.Label>
              </Col>
              <Col className="col-6">
                <Form.Check
                  disabled={(coverPicture === null && previousCoverPicture === null ) || pictureModified === true}
                  type="switch"
                  checked={coverPictureEnabled}
                  onChange={(x) => coverPictureCheckedChanged(x)}
                />
              </Col>
            </Row>
            {/* file input wont survive on re render */}
            <div className="input-group">
              <input type="file"
                     ref={fileRef}
                     key={"coverPictureInput"}
                     onChange={(x) => handleCoverPictureChanged(x)}
                     className="form-control"/>
              <Button disabled={!pictureModified} onClick={() => handleCoverPictureChanged(null)} variant="outline-secondary">Annuler</Button>
            </div>
          </Col>
        </Row>
        <Row className="gy-3 pb-2">
          <Col className="col-12 col-md-3">
            <DatePicker dateFormat="dd/MM/yy  HH:mm"
                        selected={event.startDateTime ? new Date(event.startDateTime) : null}
                        title={"Début"}
                        timeFormat="HH:mm"
                        showTimeSelect
                        minDate={Date.now()}
                        onChange={(x) => startDateChanged(x)}
                        customInput={<DateCustomInput ref={startDateRef} onCancel={(x) => startDateChanged(x)}/>}/>
          </Col>
          <Col className="col-12 col-md-3">
            <DatePicker dateFormat="dd/MM/yy  HH:mm"
                        selected={event.endDateTime ? new Date(event.endDateTime) : null}
                        title={"Fin"}
                        showTimeSelect
                        timeFormat="HH:mm"
                        minDate={event.startDateTime == null ? Date.now() : new Date(event.startDateTime)}
                        onChange={(x) => endDateChanged(x)}
                        customInput={<DateCustomInput ref={endDateRef} onCancel={(x) => endDateChanged(x)}/>}/>
          </Col>
          <Col className="col-12 col-md-6">
            <Form.Label>Lieu</Form.Label>
            <Form.Control type="text"
                          key="location-form"
                          onChange={(x) => locationChanged(x)}
                          value={event.location}/>
          </Col>
        </Row>
      </Form>
      <ReactQuill
        key="editor1"
        className="py-3"
        theme="snow"
        modules={modules}
        value={event.content}
        onChange={(x) => editorContentChanged(x)}
        ref={(el) => {
          quillRef = el;
        }}/>
      <Stack direction="horizontal" gap={2} className='d-flex justify-content-end'>
        <DropdownButton variant="light"
                        drop="up"
                        title="Google form"
        >
          { (event.form !== null) ? (
            <>
              <Dropdown.Item onClick={() => editGoogleForm()}>Modifier</Dropdown.Item>
              <Dropdown.Item onClick={() => window.open(event.form.sheetURI)}>Résultats</Dropdown.Item>
            </>
          ) :
            <CustomTooltip text={event.id == null ? saveEventErrorMsg : "Créer un formulaire sur Google Forms"}><Dropdown.Item disabled={event.id == null} onClick={() => createGoogleForm()}>Créer</Dropdown.Item></CustomTooltip>
          }
        </DropdownButton>
        {event.album ?
         <Button href={"/album/" + event.album.id} variant="light">Voir l'album</Button>
         :
         <CustomTooltip text={saveEventErrorMsg}><Button disabled={event.id == null} onClick={() => createAlbum()} variant="light">Créer album</Button></CustomTooltip>
        }
        <Button variant="danger" onClick={() => setShowDeleteDialog(true)}>Supprimer</Button>
        <Button onClick={handleSaveEvent} variant="secondary" disabled={!eventModified} >Sauvegarder</Button>
        {publishedState ?
         <Button onClick={handleHide} variant="warning">Cacher</Button>
         :
         <Button onClick={handlePublish} variant="primary">Publier</Button>
        }
      </Stack>
    </div>
  );
}

function Preview ({
  eventGetter,
  coverPicture,
}) {

  const [dateStr, setDateStr] = useState('');
  useEffect(() => {

    const d_start = new Date(eventGetter.startDateTime);
    const d_end = new Date(eventGetter.endDateTime);

    let date_time_str = "";

    date_time_str = datetime_range_to_string(d_start, d_end);

    setDateStr(date_time_str);

  }, [eventGetter]);

  return (
    <>
      <Row className="position-relative ">
        {coverPicture ? (
          <Col className="mx-auto col-xxl-8 overflow-hidden pb-2">
            <div className="ratio ratio-21x9 rounded-3  shadow" style={{backgroundImage: `url(${coverPicture})`,
                                                                        backgroundPosition: "50% 50%",
                                                                        backgroundSize: "100% auto"}}/>
          </Col> ) : (
            <Col className="mx-auto col-xxl-8 overflow-hidden pb-2">
              <div className="ratio ratio-21x9 rounded-3" style={{backgroundColor: `#b2d7ff`,
                                                                  backgroundPosition: "50% 50%",
                                                                  backgroundSize: "100% auto"}}/>
            </Col>
          )
        }
        <Col className="col-3 mx-auto text-center position-absolute bottom-0 ms-2 pb-3">
          <Col className="col-md-4">
            <Calendar2Event size={"100%"}/>
          </Col>
        </Col>
      </Row>
      <Row className="col-xxl-12 mx-auto mb-0 pb-0 border-bottom ">
        <Col className="text-danger col-12 h5 text-break text-start bd-highlight pe-0">
          {dateStr}
        </Col>
        <Col className="col-12 h3 bold text-wrap text-break text-start bd-highlight pe-0">
          {eventGetter.title}
        </Col>
        <Col className="col-12 h5 text-break text-start bd-highlight pe-0">
          {eventGetter.location}
        </Col>
      </Row>
      <div className="pt-2 gap-2 d-flex justify-content-md-end justify-content-start hstack">
        { (eventGetter.form !== null) ? (
          <Button href={eventGetter.form.responderURI} variant="primary">
            Inscription
          </Button>
        ) : null }
        { (eventGetter.album !== null) ? (
          <Button variant="primary">
            Photos
          </Button>
        ) : null }
      </div>
      <div className="publication col mx-auto px-md-4 px-2 shadow border rounded-3 mt-2  col-xxl-12 fs-5 pt-3 ql-editor-viewer"
           dangerouslySetInnerHTML={{ __html: eventGetter.content }}>
      </div>
    </>
  );
}

function ConfirmDelete({
  show,
  showSetter,
  event,
}) {

  async function handleClose(confirmed) {
    showSetter(false);
    if (!confirmed) { return; }

    let resp = await REST.delete('events/' + event.id + '/');
    if (resp) {
      window.location = '/events';
    }
  }

  return (
    <Modal show={show}>
      <Modal.Header closeButton onHide={() => handleClose(false)}>
        <Modal.Title>Confirmation de suppression</Modal.Title>
      </Modal.Header>
      <Modal.Body>Êtes-vous de sûr de vouloir supprimer cet évènement? Cette action est irréversible.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleClose(false)}>
          Annuler
        </Button>
        <Button variant="danger" onClick={() => handleClose(true)}>
          Supprimer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditEventPage;


import {useEffect, useState} from 'react';
import REST from '../rest/pharma_nam';
import { Image, Stack, ListGroup, Form, Alert, Card, Button, Container } from 'react-bootstrap';
import {BasicInputDialog} from '../components/dialogs';
import { sleep } from './Users';
import FailurePage from './FailurePage';

function SponsorsPage(){
    const [sponsors, setSponsors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [name, setSponsorName] = useState("");
    const [linkUrl, setLinkUrl] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [showAlert, setShowAlert] = useState({ 'success': null, 'msg': '' });

    useEffect(() => {
        async function loadSponsors(){
            const response = await REST.getJSONNoAuth('sponsors');
            setIsLoading(false);
            if(response !== null){
                const loadedSponsors = response.map((x) => <SponsorTile setAlert={(x) => setShowAlert(x) } key={x} setSponsors={(x) => setSponsors(x)} sponsor={x}/>);                
                setSponsors(loadedSponsors);
            }
        }

        loadSponsors();
    }, [])

    var profile = REST.getProfile();
    if (!profile || profile.role.name !== 'admin')
      return <FailurePage/>;

    if(isLoading){
        return (<></>);
    }

    const form =  
            <Form>
                <Form.Group className="mb-3" controlId="sponsorForm.name">
                <Form.Label>Nom du sponsor</Form.Label>
                <Form.Control onChange={(x) => setSponsorName(x.target.value)} value={name} type="text" placeholder="Université de Namur" />
                </Form.Group>
                <Form.Group className="mb-3" type="text">
                <Form.Label>Lien de l'image du sponsor</Form.Label>
                <Form.Control onChange={(x) => setImgUrl(x.target.value)} value={imgUrl} type="text" placeholder="http://mon_sponsor.jpg" />
                </Form.Group>
                <Form.Group className="mb-3" type="text">
                <Form.Label>Lien du site web du sponsor</Form.Label>
                <Form.Control onChange={(x) => setLinkUrl(x.target.value)} value={linkUrl} type="text" placeholder="http://mon_sponsor.be" />
                </Form.Group>
            </Form>

    return (
        <>
        <Alert show={showAlert.success} variant="success" className="mb-3 py-2 text-center">
        {showAlert.msg}
        </Alert>
        <Alert show={showAlert.success === false} variant="danger" className="mb-3 py-2 text-center">
            {showAlert.msg}
        </Alert>
        <Container className="col col-12 py-2 ">
         <Stack direction="horizontal" gap={3} className='py-3'>
            <Button onClick={() => setShowDialog(true)}>Nouveau sponsor</Button>
        </Stack>
        <BasicInputDialog form={form} show={showDialog} onConfirm={() => createSponsor({name, imgUrl, linkUrl})} onClose={(x) => setShowDialog(false)} title="Ajouter un sponsor" />
        
        <ListGroup className='d-flex flex-wrap justify-content-center' horizontal> 
        {sponsors}
        </ListGroup>  
        </Container>
        </>
    );


    async function createSponsor(sponsor){
        const creationResponse = await REST.postJSON('sponsors/', sponsor);
        if(creationResponse !== null){
            setShowAlert({"success": true, "msg" : "Sponsor créé avec succès"});
            const response = await REST.getJSONNoAuth('sponsors');
            if(response !== null){
                const loadedSponsors = response.map((x) => <SponsorTile setAlert={(x) => setShowAlert(x) } key={x} setSponsors={(x) => setSponsors(x)} sponsor={x}/>);                
                setSponsors(loadedSponsors);
            }
        } else {
            setShowAlert({"success": false, "msg" : "Erreur inconnue survenue "});
        }
        setShowDialog(false);
    }

}

// todo: edit sponsor
function SponsorTile({key, sponsor, setAlert, setSponsors}){


    async function deleteSponsor() {
        if(sponsor === null){
            return;
        }
    
        const deletionResponse = await REST.delete('sponsors/' + sponsor.id);
        if(deletionResponse !== null){
            setAlert({"status": "success", "msg": "Sponsor supprimé avec succès"});
            const response = await REST.getJSONNoAuth('sponsors');
            if(response !== null){
                const loadedSponsors = response.map((x) => <SponsorTile setAlert={(x) => setAlert(x) } key={x} setSponsors={(x) => setSponsors(x)} sponsor={x}/>);                
                setSponsors(loadedSponsors);
            }
        }
    }


    return (
        <div key={key} className="mx-2 px-4 py-4 ">
        <Card style={{height:'300px', width:'250px', borderRadius: '35px'}}>
            <div className='mx-auto py-2' style={{maxHeight: '150px', maxWidth:"150px"}} >
                <Image src={sponsor.imgUrl} thumbnail ></Image>
            </div>
            <Card.Body className="py-2 text-center">
                <Card.Title>{sponsor.name}</Card.Title>
                <Button variant='primary' style={{position:'absolute', top:'278px', right:'38px'}} onClick={() => deleteSponsor()}>Supprimer sponsor</Button>
            </Card.Body>
        </Card>
        </div>
      ); 
}





export {SponsorsPage, SponsorTile};
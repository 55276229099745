import { createContext, useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class QueueItem {
  constructor(callback, data) {
    this.callback = callback;
    this.status = null;
    this.data = data;
  }
}

class QueueController {
  constructor() {
    this.wait_queue = [];
    this.done_queue = [];
    this.done_callback = null;

  }

  async start() {
    while (this.wait) {
      await this.process_next();
    }
    this.done_callback();
  }

  get size() {
    return this.wait + this.done;
  }

  get wait() {
    return this.wait_queue.length;
  }

  get done() {
    return this.done_queue.length;
  }

  get progress_percent()
  {
    return (this.done / this.size) * 100;
  }

  insert(item) {
    this.wait_queue.unshift(item);
  }

  async process_next() {
    let item = this.wait_queue.pop();
    item.status = await item.callback(item.data);
    this.done_queue.push(item);
  }
}
const DownloadStatusContext = createContext();

function DownloadStatusModal() {
  const [showed, setShowed] = useState(false);
  return (
    <Modal show={showed}>
      <Modal.Header closeButton onHide={() => setShowed(false)}>
        <Modal.Title>Confirmation de suppression</Modal.Title>
      </Modal.Header>
      <Modal.Body>Êtes-vous de sûr de vouloir supprimer les éléments sélecionnés? Cette action est irréversible.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowed(false)}>
          Annuler
        </Button>
        <Button variant="danger" onClick={() => setShowed(false)}>
          Supprimer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export { DownloadStatusModal, QueueController , QueueItem, DownloadStatusContext };

import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Placeholder } from 'react-bootstrap';

import REST from '../rest/pharma_nam';
import { datetime_to_fr_short_date } from '../models/Models';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function ArticlePage() {

  let article_id = useParams().id;
  let profile = REST.getProfile();
  const [fetchDone, setFetchDone] = useState(null);
  const [article, setArticle] = useState(null);
  const [coverPicture, setCoverPicture] = useState(null);

  useEffect(() => {
    async function getArticle() {
      let response;
      if (profile.role.name === "anonymous") {
        response = await REST.getJSONNoAuth("articles/" + article_id);
      }
      else {
        response = await REST.getJSON("articles/" + article_id);
      }
      setFetchDone(true);
      setArticle(response);

      if (response.cover) {
        setCoverPicture(response.cover)
        }
    }

    // TODO
    // const timer = sleep(300);
    // timer.then( (x) => {
    //   if (fetchDone === null) {
    //     setFetchDone(false);
    //   }
    // });

    getArticle();
  }, []);

  if (fetchDone === null) {
    return (<></>);
  }

  else {
    const date = new Date(article.createdAt);
    return (
      <Container className="col col-12 py-2 col-xl-7">
        {fetchDone ?
         (article !== null ?
          <>
            <Row className="d-flex gy-2 justify-content-between text-center text-md-start  align-items-center">
              <Col className="col-12 col-md-6 " >
                <Stack direction='horizontal' gap='3' className="mt-2 py-2 px-3 border rounded d-inline-flex">
                  <h5 className="text-secondary ">{article.author.first_name} {article.author.last_name}</h5>
                  <div className="vr"/>
                  <h5 className="text-secondary">{ datetime_to_fr_short_date(new Date(date))}</h5>
                </Stack>
              </Col>

              {(profile.role.name === 'publisher' || profile.role.name === 'admin') ?
               <Col className="col-12 col-md-2">
                 <Button href={"/edit_article/" + article_id} variant="outline-primary">Modifier</Button>
               </Col>
               :
               null}
            </Row>
            <div className="py-3 h1 text-wrap text-break text-center text-xl-start bd-highlight">
              {article.title}
            </div>
            {coverPicture &&
             <div className="col mx-auto col-xxl-10">
               <img src={coverPicture.url}
                    alt="Cover"
                    className="object-fit-none w-100 rounded-3 border mx-auto d-block mb-2"
               />
             </div>
            }
            <div className="publication col mx-auto col-xxl-9 ql-editor-viewer"
                 dangerouslySetInnerHTML={{ __html: article.content }}></div>
          </>
          :
          <Alert variant="danger">
            Article introuvable...
          </Alert>
         )
         :
         <GetPlaceholder/>
        }
      </Container>
    );
  }
}

function GetPlaceholder() {
  return (
    <>
      <Placeholder as="h3" className="w-25"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
      <Placeholder className="w-25"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
      <Placeholder className="w-25"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
    </>
  );
}

export default ArticlePage;

import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import REST from '../rest/pharma_nam'
import FailurePage from './FailurePage';

function RefreshTokenPage(){

    var profile = REST.getProfile();
    async function getAuthUrl()  { 
        const response = await REST.getJSON(
            "google-redirect"
        );

        if(response !== null){
            window.location = response.auth_url;
        }
    }   


    if (!profile || profile.role.name !== 'admin')
      return <FailurePage/>;

    return (
        <>
        <Container>
            <Row >
                <Col className='d-flex justify-content-center mt-4'>Pour renouveler l'accès au compte gmail de l'application, veuillez effectuer une nouvelle connexion sur le compte</Col>
            </Row>
            <Row >
                <Col className='d-flex justify-content-center mt-4'>
                    <Button variant='primary' onClick={getAuthUrl}>
                        Renouveler le refresh token
                    </Button>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default RefreshTokenPage;
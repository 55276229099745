import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { Placeholder } from 'react-bootstrap';
import { Calendar2Event } from "react-bootstrap-icons";

import REST from '../rest/pharma_nam';
import { datetime_range_to_string } from '../models/Models';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function EventPage() {

  let event_id = useParams().id;
  let profile = REST.getProfile();
  const [fetchDone, setFetchDone] = useState(null);
  const [event, setEvent] = useState(null);
  const [coverPicture, setCoverPicture] = useState(null);

  useEffect(() => {
    async function getEvent() {
      let response;
      if (profile.role.name === "anonymous") {
        response = await REST.getJSONNoAuth("events/" + event_id);
      }
      else {
        response = await REST.getJSON("events/" + event_id);
      }
      setFetchDone(true);
      setEvent(response);

      if (response && response.cover) {
        setCoverPicture(response.cover)
      }
    }

    // TODO
    // const timer = sleep(300);
    // timer.then( (x) => {
    //   if (fetchDone === null) {
    //     setFetchDone(false);
    //   }
    // });

    getEvent();
  }, []);

  if (fetchDone === null) {
    return (<></>);
  }

  else {
    return (
      <Container className="col col-12 py-2 col-xl-7">
        { (fetchDone && event !== null ) ? (
          <>
            { (profile.role.name === 'publisher' || profile.role.name === 'admin') ? (
              <Row className="d-flex my-2 justify-content-end text-end text-md-end  align-items-center">
                <Col className="col-12 col-md-2">

                  <Dropdown  drop="start">
                    <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                      Gestion
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="start">
                      <Dropdown.Item href={"/edit_event/" + event_id}>Modifier</Dropdown.Item>
                      <Dropdown.Item disabled={!event.form}
                                     onClick={event.form ? (() => window.open(event.form.sheetURI)) : null}>
                        Résultats d'inscriptions
                      </Dropdown.Item>
                      <Dropdown.Item disabled={!event.album}
                                     href={event.album ? ("/album/" + event.album.id) : null}>
                        Album photo
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            ) : ( null )}
            <Row className="position-relative ">
              {coverPicture ? (
                <Col className="mx-auto col-xxl-8 overflow-hidden pb-2">
                  <div className="ratio ratio-21x9 rounded-3  shadow" style={{backgroundImage: `url(${coverPicture.url})`,
                                                                              backgroundPosition: "50% 50%",
                                                                              backgroundSize: "100% auto"}}/>
                </Col> ) : (
                  <Col className="mx-auto col-xxl-8 overflow-hidden pb-2">
                    <div className="ratio ratio-21x9 rounded-3" style={{backgroundColor: `#b2d7ff`,
                                                                                backgroundPosition: "50% 50%",
                                                                                backgroundSize: "100% auto"}}/>
                  </Col>
                )
              }
              <Col className="col-3 mx-auto text-center position-absolute bottom-0 ms-2 pb-3">
                <Col className="col-md-4">
                  <Calendar2Event size={"100%"}/>
                </Col>
              </Col>
            </Row>
            <Row className="col-xxl-12 mx-auto mb-0 pb-0 border-bottom ">
              <Col className="text-danger col-12 h5 text-break text-start bd-highlight pe-0">
                {datetime_range_to_string(
                  new Date(event.startDateTime),
                  new Date(event.endDateTime))
                }
              </Col>
              <Col className="col-12 h3 bold text-wrap text-break text-start bd-highlight pe-0">
                {event.title}
              </Col>
              <Col className="col-12 h5 text-break text-start bd-highlight pe-0">
                {event.location}
              </Col>
            </Row>
            <div className="pt-2 gap-2 d-flex justify-content-md-end justify-content-start hstack">
              { (event.form !== null) ? (
                <Button href={event.form.responderURI} variant="primary">
                  Inscription
                </Button>
              ) : null }
              { (event.album !== null) ? (
                <Button variant="primary" href={"/album/" + event.album.id}>
                  Photos
                </Button>
              ) : null }
            </div>
            <div className="publication col mx-auto px-md-4 px-2 shadow border rounded-3 mt-2  col-xxl-12 fs-5 pt-3 ql-editor-viewer"
                 dangerouslySetInnerHTML={{ __html: event.content }}>
            </div>
          </>
        ) : (
          <Alert variant="danger">
            Article introuvable...
          </Alert>
        )}
      </Container>
    );
  }
}

function GetPlaceholder() {
  return (
    <>
      <Placeholder as="h3" className="w-25"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
      <Placeholder className="w-25"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
      <Placeholder className="w-25"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
      <Placeholder className="w-50"></Placeholder>
    </>
  );
}

export default EventPage;

import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Alert } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";

import REST from '../rest/pharma_nam'

function RegisterPage() {
  const [passwordType, setPasswordType] = useState("password");
  const [password, setPassword] = useState("");

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState({ 'success': null, 'msg': '' });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [description, setDescription] = useState("");
  const [isUserHuman, setIsUserHuman] = useState(false);

  const [validated, setValidated] = useState(false);
  let isValid = false;
  const recaptchaTestKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
  const recaptchaKey = "6LcFAZgpAAAAAHTjfvEh7L4XtsgS6G4nZJvnSDju";
  const isTest = window.location.href.includes("localhost") || window.location.href.includes("127.0.0.1");
  
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === true) {
      register(email, username, password, confirmPassword, firstName, lastName, description, (y) => setShowAlert(y));
    }  
  
    setValidated(true);
  };


  function showPasswordChange(event) {
    if (event.target.checked) {
      setPasswordType('text');
    }
    else {
      setPasswordType('password');
    }
  }

  return (
    <Container className='py-5' style={{maxWidth: 55 + 'rem'}}>
      <Alert show={showAlert.success} variant="success" className="mb-3 py-2 text-center">
        {showAlert.msg}
      </Alert>
      <Alert show={showAlert.success === false} variant="danger" className="mb-3 py-2 text-center">
        {showAlert.msg}
      </Alert>
      
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row>
        <Col sm={4}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Adresse Email</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(x) => setEmail(x.target.value)}
          />
          <Form.Control.Feedback type="invalid">Email non conforme</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicUsername">
          <Form.Label>Nom d'utilisateur</Form.Label>
          <Form.Control
            required
            placeholder="Enter username"
            pattern="^[A-Za-z0-9@.+\-_]+$"
            value={username}
            onChange={(x) => setUsername(x.target.value)}
          />
          <Form.Control.Feedback type="invalid">Nom d'utilisateur non conforme</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Mot de passe</Form.Label>
          <Form.Control
            required
            minLength={6}
            type={passwordType}
            value={password}
            onChange={(x) => setPassword(x.target.value)}
            placeholder="Password" />
          <Form.Control.Feedback type="invalid">Mot de passe pas assez long (min 6 caractères)</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Confirmer mot de passe</Form.Label>
          <Form.Control
            required
            minLength={6}
            type={passwordType}
            value={confirmPassword}
            onChange={(x) => setConfirmPassword(x.target.value)}
            placeholder="Password" />
            <Form.Control.Feedback type="invalid">Mot de passe pas assez long (min 6 caractères)</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3"  controlId="formBasicCheckbox">
          <Form.Check
            reverse
            type="switch"
            label="Afficher le mot de passe"
            onChange={(x) => showPasswordChange(x)}
          />
        </Form.Group>       
        </Col>
        <Col sm={2}></Col>
        <Col sm={6}>
        <Form.Group className="mb-3" controlId="formBasicFirstName">
          <Form.Label>Prénom</Form.Label>
          <Form.Control
            required
            pattern="^[a-zA-Z][a-zA-Z\- ]*$"
            type="text"
            value={firstName}
            onChange={(x) => setFirstName(x.target.value)}
            placeholder="Joe" />
            <Form.Control.Feedback type="invalid">Prenom non conforme</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicSurname">
          <Form.Label>Nom</Form.Label>
          <Form.Control
            type="text"
            required
            pattern="^[a-zA-Z][a-zA-Z\- ]*$"
            value={lastName}
            onChange={(x) => setLastName(x.target.value)}
            placeholder="Shmoe" />
            <Form.Control.Feedback type="invalid">Nom non conforme</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            minLength={30}
            maxLength={199}
            rows={5}
            type="text"
            value={description}
            onChange={(x) => setDescription(x.target.value)}
            placeholder="Je suis passioné par ..." />
            <Form.Control.Feedback type="invalid">La description doit faire entre 30 et 200 caractères</Form.Control.Feedback>
        </Form.Group>
        </Col>
        </Row>
        <div className='mb-3 d-flex justify-content-center'>
            <ReCAPTCHA /* If localhost, then test keys provided by google can be used, else (if the hostname == pharmanam.be) then the keys created can be used.*/
            sitekey= { isTest ?  recaptchaTestKey : recaptchaKey}
            onExpired={() => setIsUserHuman(false)}
            onChange={ async (token)  =>   {
              const response =  await REST.getJSONNoAuth('recaptcha?captcha_token=' + token + (isTest ? '&is_test=true' :''));  
              if(response !== null)
                setIsUserHuman(true);
              else
                setShowAlert({'success': false, 'msg': "Test recaptcha échoué, êtes vous un robot?" });
            } }
            />
         </div>
        <div className='d-flex justify-content-center'>
          <Button className="col-10 " disabled={!isUserHuman} type="submit"
            variant="primary">
            Soumettre
          </Button>
        </div>
      </Form>      
    </Container>
  );
}

function register(email, username, password, confirmPassword, first_name, last_name, description, alertSetter){
  
  if(password !== confirmPassword){
      alertSetter({'success': false, 'msg': "Les mots de passe ne correspondent pas" });
      return;
  }

  async function signUp(){ 
      const response = await REST.register(email, username, password, first_name, last_name, description);
      if(response.status === 201){
          alertSetter({'success': true, 'msg': "Nous vous avons envoyé un mail afin de confirmer votre adresse!"});
      } 
      else if (response.status === 400){
        let json = await response.json(); 
        alertSetter({'success': false, 'msg': getErrorMessage(json) });
      }
      else if (response.status === 406){
        alertSetter({'success': false, 'msg': "Ce nom d'utilisateur est déjà utilisé"});
      }
      else {
          alertSetter({'success': false, 'msg': 'Veuillez réessayer'});
      }
  }
  signUp();
}

function getErrorMessage(json){
    if(json === null || json === undefined){
        return;
    }

    if('username' in json){
        if(json.username.includes("already exists")){
            return "Ce nom d'utilisateur est déjà utilisé"
        }
        return "Le nom d'utilisateur n'est pas conforme. Il ne peut contenir que des lettres, chiffres\n ou les caractères suivant: @/./+/-/_"
    } else if ('email' in json){
        if(json.email.includes("already exists")){
            return "Cette adresse mail est déjà utilisée";
        }
        return "L'adresse mail n'est pas conforme";
    }
    
    return "Erreur inconnue";
}


export default RegisterPage;
